import { createApi } from '@reduxjs/toolkit/query/react';
import { ApiEndpoints, IEVSettings, ILocationSettings, IScenarioSettings, Methods } from '../../constants';
import { interceptor } from '../../utils/interceptor';

interface ILocationPayload {
  scenarioId: string
  latitude: number
  longitude: number
  abatement_latitude: number
  abatement_longitude: number
}

export const settingsApis = createApi({
  reducerPath: 'settingsApis',
  baseQuery: interceptor,
  tagTypes: ['GetSettings'],
  endpoints: (builder) => ({
    // api to save Project Data...
    saveSettings: builder.mutation<IScenarioSettings, Partial<IScenarioSettings>>({
      query: (payload) => ({
        url: ApiEndpoints.SAVE_SCENARIO_SETTINGS,
        method: Methods.POST,
        body: payload
      }),
      transformErrorResponse: (error) => {
        return { message: (error.data as any).error, status: error.status };
      },
      invalidatesTags: ['GetSettings']
    }),

    // api to get all projects...
    getSettingsData: builder.query<IScenarioSettings, string>({
      query: (scenarioId) => ({
        url: `${ApiEndpoints.GET_SCENARIO_SETTINGS}?model_id=${scenarioId}`,
        method: Methods.GET
      }),
      providesTags: ['GetSettings']
    }),

    // api to save location settings
    saveLocationSettings: builder.mutation<ILocationSettings, ILocationPayload>({
      query: (payload: ILocationPayload) => ({
        url: `${ApiEndpoints.SAVE_LOCATION_SETTINGS}?scenario_id=${payload.scenarioId}`,
        method: Methods.POST,
        body: { latitude: payload.latitude, longitude: payload.longitude, abatement_latitude: payload.abatement_latitude, abatement_longitude: payload.abatement_longitude }
      }),
      transformErrorResponse: (error) => {
        return { message: (error.data as any).error, status: error.status };
      },
      invalidatesTags: ['GetSettings']
    }),

    saveEVSettings: builder.mutation<IEVSettings, Partial<IEVSettings>>({
      query: (payload) => ({
        url: `${ApiEndpoints.SAVE_SCENARIO_SETTINGS}`,
        method: Methods.POST,
        body: payload
      }),
      transformErrorResponse: (error) => {
        return { message: (error.data as any).error, status: error.status };
      },
      invalidatesTags: ['GetSettings']
    })
  })
});

export const {
  useSaveSettingsMutation,
  useGetSettingsDataQuery,
  useLazyGetSettingsDataQuery,
  useSaveLocationSettingsMutation,
  useSaveEVSettingsMutation
} = settingsApis;
