import { createSlice } from '@reduxjs/toolkit';
import { IScenarioSettings } from '../../constants';

const initialState: IScenarioSettings = {
  _id: '',
  latitude: '',
  longitude: '',
  discountRate: '',
  timeSteps: '',
  ccTax: '',
  terminalValue: '',
  noOfYears: '',
  startYear: '',
  subsidies: '',
  subProjectID: '',
  energyShortfallPriority: '',
  excessEnergyPriority: '',
  componentPriority: '',
  powerComponentPriority: '',
  disableComponents: [],
  economic_model: '1',
  inflation_rate_during_construction: '0',
  fixed_charge_rate: '0.5',
  LicenseFees: '10',
  PermittingCosts: '10',
  TaxCredits: '1',
  TaxCredit45Q: '0.5',
  DebtInterestPayment: '5',
  DirectorExpenses: '10',
  TotalCOGS: '10',
  DirectExpenses: '10'
  // is_pw_and_a: false,
  // is_dac: false,
  // sequestration_type: 'tertiary',
  // pc_scheme: 'ptc',
  // is_domestic_content: true,
  // is_energy_community: true,
  // is_ira: true
};

const workbenchSettings = createSlice({
  name: 'WorkbenchSettings',
  initialState,
  reducers: {
    updateWorkbenchSettings: (state, payload) => {
      return { ...state, ...payload.payload };
    },
    resetWorkbenchSettings: (state) => {
      return { ...state, ...initialState };
    }
  }
});

export const { updateWorkbenchSettings, resetWorkbenchSettings } = workbenchSettings.actions;

export default workbenchSettings.reducer;
