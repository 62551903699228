import React, { useEffect, useState } from 'react';
import {
  capitalizeAndRemoveUnderscore,
  carbonTaxLogo,
  electricityGraphImg,
  energyConsumptionLogo,
  formatAndRoundOffNumber,
  formatIntegerOnly,
  irrLogo,
  nvpLogo,
  productionLogo,
  taxCreditLogo
} from '../../utils';
import { CCUS, GEO_THERMAL, GIGADAC, HYDROGEN, NUMBER, POWER } from '../../constants';
import GeoThermalEconomics from '../workbench/GeoThermalEconomics';
import { Button } from 'react-bootstrap';
import PopoverModal from './CostBreakdownModal';
import { useAppSelector } from '../../redux/hooks';
interface IProps {
  metricsData: any
  projectType: string
}

const EconomicsData = ({ metricsData, projectType }: IProps) => {
  const [showPopOverModal, setShowPopOverModal] = useState(false);
  const [capexBreakdown, setCapexBreakdown] = useState<any>();
  const [opexBreakdown, setOpexBreakdown] = useState<any>();
  const [periodCostBreakdown, setPeriodCostBreakdown] = useState<any>();
  const [modalData, setModalData] = useState<Record<string, any>>();
  const [modalTitle, setModalTitle] = useState('');
  const [modalUnit, setModalUnit] = useState('');
  const workbenchNodes = useAppSelector(state => state.workbench.nodes);

  useEffect(() => {
    if (metricsData?.cost_metrics !== undefined) {
      let capexValue, opexValue, periodCostValue;

      metricsData.cost_metrics.forEach((obj: any) => {
        if ('capex' in obj) {
          capexValue = obj.capex;
        }
        if ('opex' in obj) {
          opexValue = obj.opex;
        }
        if ('period_value' in obj) {
          periodCostValue = obj.period_value;
        }
      });
      setCapexBreakdown(capexValue);
      setOpexBreakdown(opexValue);
      setPeriodCostBreakdown(periodCostValue);
    }
  }, [metricsData]);

  const formatModalData = (title: string, data: Record<string, any>) => {
    const dataObj: Record<string, any> = {};
    data?.map((key: any) => {
      if (key.object_name === 'simulation') {
        return null;
      }
      const metricName = capitalizeAndRemoveUnderscore(key.object_name);
      dataObj[metricName] = {
        value: key.value,
        unit: key.unit
      };
    });
    return dataObj;
  };

  const openModal = (title: string, data: any, unit?: string) => {
    setModalTitle(title);
    setModalUnit(unit ?? '');
    const updateData = formatModalData(title, data);
    setModalData(updateData);
    setShowPopOverModal(true);
  };

  const powerData = () => (
    <div className="simulation-chart-wrapper mitigation-chart power-simulation-chart-wrapper">
      <div className="mitigation-wrap">
        <ScopeEmissions />
        <div className="mitigation-right-wrap">
          <div className="supply-right power-supply-right">
            <div className="tax-credits co2-wrap">
              <span className="section-logo">
                <img src={productionLogo} alt="logo" />
              </span>
              <div className="suppy-credit-wrap">
                <p className="credits-value">{formatIntegerOnly(metricsData?.Total_Energy_Produced)}</p>
                <span className="credits-des">Total Energy Produced</span>
                <span className="credits-des">(kWh/yr)</span>
              </div>
            </div>
            <div className="carbon-tax co2-wrap">
              <span className="section-logo">
                <img src={energyConsumptionLogo} alt="logo" />
              </span>
              <div className="suppy-credit-wrap">
                <p className="credits-value">{formatIntegerOnly(metricsData?.Total_Energy_Consumed)}</p>
                <span className="credits-des">Total Energy Consumed</span>
                <span className="credits-des">(kWh/yr)</span>
              </div>
            </div>
          </div>
          <div className="supply-nvp fix-duplicacy">
            <div className="nvp-left co2-wrap">
              <div className="tax-credits">
                <span className="section-logo nvp-sec-logo">
                  <img src={nvpLogo} alt="logo" />
                </span>
                <div className="suppy-credit-wrap npv-credit-wrap">
                  <p className="credits-value npv-value">NPV</p>
                  <span className="credits-des npv-des">
                    ${formatIntegerOnly(metricsData?.NPV, '-')}
                  </span>
                </div>
              </div>
            </div>
            <div className="nvp-right co2-wrap fix-duplicacy">
              <div className="tax-credits">
                <span className="section-logo nvp-sec-logo">
                  <img src={irrLogo} alt="logo" />
                </span>
                <div className="suppy-credit-wrap npv-credit-wrap">
                  <p className="credits-value npv-value">IRR</p>
                  <span className="credits-des npv-des">{metricsData?.IRR < NUMBER.N1 ? '-' : formatIntegerOnly(metricsData?.IRR, '-')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cost-wrap fix-duplicacy">
        <div className="cost-img-sec">
          <div className="cost-img-wrap">
            <div className="cost-img-inner-wrap">
              <img src={electricityGraphImg} alt="logo img" />
              <h3>Cost</h3>
            </div>
          </div>
          <div className="cost-text-wrap fix-duplicacy">
            <div className="cost-text-inner-wrap">
              <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                openModal('Capital Costs', capexBreakdown)}>
                  Total Capex
              </Button>
              <h2 className="cost-text-head">${formatIntegerOnly(metricsData?.Capex)}</h2>
            </div>
            <div className="cost-text-inner-wrap fix-duplicacy">
              <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                openModal('Opex/yr', opexBreakdown)}>
                  Opex/yr
              </Button>
              <h2 className="cost-text-head">${formatIntegerOnly(metricsData?.Opex)}</h2>
            </div>
            <div className="cost-text-inner-wrap fix-duplicacy">
              <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                openModal('Period Cost/yr', periodCostBreakdown)}>
                  Period Cost/yr
              </Button>
              <h2 className="cost-text-head">${formatIntegerOnly(metricsData?.Period_Value)}</h2>
            </div>
          </div>
        </div>
        <div className="cost-value-one fix-duplicacy">
          <span className="cost-value-head">LCOE/kWh</span>
          {metricsData?.LCOE_Total > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap ">
            <span className="mitigation-label">Total</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Total)}</p>
          </div>}
          {metricsData?.LCOE_Solar > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label fix-duplicacy">Solar</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Solar)}</p>
          </div>}
          {metricsData?.LCOE_Turbine > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label fix-duplicacy">Turbine</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Turbine)}</p>
          </div>}
          {metricsData?.LCOE_Battery > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label fix-duplicacy">Battery</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Battery)}</p>
          </div>}
          {metricsData?.LCOE_Diesel > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label fix-duplicacy">Diesel</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Diesel)}</p>
          </div>}
          {metricsData?.LCOE_Gas > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Natural Gas</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Gas)}</p>
          </div>}
          {metricsData?.Fuel_Cell_LCOE > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Fuel Cell</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.Fuel_Cell_LCOE)}</p>
          </div>}
        </div>
        <div className="cost-value-two fix-duplicacy">
          <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Local Tax Credit</span>
            <p className="lcoe-value">${formatIntegerOnly(metricsData?.Local_Tax_Credits, '-')}</p>
          </div>
          <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Carbon Tax</span>
            <p className="lcoe-value">${formatIntegerOnly(metricsData?.Carbon_Tax, '-')}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const ccusData = () => (
    <div className="simulation-chart-wrapper mitigation-chart ">
      <div className="mitigation-wrap">
        <ScopeEmissions classname="hydrogen-emission" />
        <div className="mitigation-right-wrap">
          <div className="co2-wrap supply-left">
            <h4 className="section-head">Supply Reliability</h4>
            {metricsData?.total_gaseous_co2_sold !== undefined &&
            <>
              <div className="co2-inner-wrap">
                <span className="mitigation-label">
                Total Gaseous CO2 sold
                </span>
                <p className="mitigation-value">{formatIntegerOnly(metricsData?.total_gaseous_co2_sold, '-')} Kg/yr</p>
              </div>
            </>}
            {metricsData?.total_gaseous_co2_shortfall !== undefined &&
            <>
              <div className="co2-inner-wrap">
                <span className="mitigation-label">
                Total Gaseous CO2 shortfall
                </span>
                <p className="mitigation-value">{formatIntegerOnly(metricsData?.total_gaseous_co2_shortfall, '-')} Kg/yr</p>
              </div>
            </>}
            {metricsData?.avg_gaseous_co2_shortfall_per_year !== undefined &&
            <>
              <div className="co2-inner-wrap">
                <span className="mitigation-label">
                Average Gaseous CO2 shortfall
                </span>
                <p className="mitigation-value">{formatIntegerOnly(metricsData?.avg_gaseous_co2_shortfall_per_year, '-')} Kg/yr</p>
              </div>
            </>}
            {metricsData?.avg_liquid_co2_shortfall_per_year !== undefined &&
            <>
              <div className="co2-inner-wrap">
                <span className="mitigation-label">
                Average Liquid CO2 shortfall
                </span>
                <p className="mitigation-value">{formatIntegerOnly(metricsData?.avg_liquid_co2_shortfall_per_year, '-')} Kg/yr</p>
              </div>
            </>}
          </div>
          <div className="supply-right fix-duplicacy">
            <div className="tax-credits co2-wrap">
              <span className="section-logo">
                <img src={taxCreditLogo} alt="logo" />
              </span>
              <div className="suppy-credit-wrap">
                <p className="credits-value">${formatIntegerOnly(metricsData?.Local_Tax_Credits, '-')}</p>
                <span className="credits-des">Local Tax Credits</span>
              </div>
            </div>
            <div className="carbon-tax co2-wrap fix-duplicacy">
              <span className="section-logo">
                <img src={carbonTaxLogo} alt="logo" />
              </span>
              <div className="suppy-credit-wrap">
                <p className="credits-value">${formatIntegerOnly(metricsData?.Carbon_Tax, '-')}</p>
                <span className="credits-des">Carbon Tax</span>
              </div>
            </div>
          </div>
          <div className="supply-nvp fix-duplicacy">
            <div className="nvp-left co2-wrap">
              <div className="tax-credits">
                <span className="section-logo nvp-sec-logo">
                  <img src={nvpLogo} alt="logo" />
                </span>
                <div className="suppy-credit-wrap npv-credit-wrap">
                  <p className="credits-value npv-value">NPV</p>
                  <span className="credits-des npv-des">
                    ${formatIntegerOnly(metricsData?.NPV, '-')}
                  </span>
                </div>
              </div>
            </div>
            <div className="nvp-right co2-wrap">
              <div className="tax-credits">
                <span className="section-logo nvp-sec-logo">
                  <img src={irrLogo} alt="irr-logo" />
                </span>
                <div className="suppy-credit-wrap npv-credit-wrap">
                  <p className="credits-value npv-value">IRR</p>
                  <span className="credits-des npv-des">{metricsData?.IRR < NUMBER.N1 ? '-' : formatIntegerOnly(metricsData?.IRR, '-')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cost-wrap">
        <div className="cost-img-sec fix-duplicacy">
          <div className="cost-img-wrap">
            <div className="cost-img-inner-wrap">
              <img src={electricityGraphImg} alt="logo img" />
              <h3>Cost</h3>
            </div>
          </div>
          <div className="cost-text-wrap">
            <div className="cost-text-inner-wrap">
              <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                openModal('Capital Costs', capexBreakdown)}>
                  Total Capex
              </Button>
              <h2 className="cost-text-head">${formatIntegerOnly(metricsData?.Capex)}</h2>
            </div>
            <div className="cost-text-inner-wrap">
              <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                openModal('Opex/yr', opexBreakdown)}>
                  Opex/yr
              </Button>
              <h2 className="cost-text-head">${formatIntegerOnly(metricsData?.Opex)}</h2>
            </div>
            <div className="cost-text-inner-wrap">
              <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                openModal('Period Cost/yr', periodCostBreakdown)}>
                  Period Cost/yr
              </Button>
              <h2 className="cost-text-head">${formatIntegerOnly(metricsData?.Period_value)}</h2>
            </div>
          </div>
        </div>
        <div className="cost-value-two">
          <span className="cost-value-head">Levelized Cost of Gaseous CO2 ($/mt CO2)</span>
          <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Total</span>
            <p className="lcoe-value">{formatAndRoundOffNumber(metricsData?.levelized_costs_of_gaseous_co2)}</p>
          </div>
          <span className="cost-value-head">LCOE/kWh</span>
          <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Total</span>
            <p className="lcoe-value">{formatAndRoundOffNumber(metricsData?.LCOE_Total)}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const hydrogenData = () => (
    <div className="simulation-chart-wrapper mitigation-chart ">
      <div className="mitigation-wrap">
        <ScopeEmissions classname="hydrogen-emission" />
        <div className="mitigation-right-wrap">
          <div className="co2-wrap supply-left">
            <h4 className="section-head">Supply Reliability</h4>
            <div className="co2-inner-wrap">
              <span className="mitigation-label">
                Total Power Produced
              </span>
              <p className="mitigation-value">{formatIntegerOnly(metricsData?.Total_Power_Produced, '-')} kWh/Yr</p>
            </div>
            <div className="co2-inner-wrap">
              <span className="mitigation-label">
                Total Hydrogen Produced
              </span>
              <p className="mitigation-value">{formatIntegerOnly(metricsData?.Total_Hydrogen_Produced, '-')} Kg/Yr</p>
            </div>
            <div className="co2-inner-wrap">
              <span className="mitigation-label">
                Cumulative Hydrogen Shortfall
              </span>
              <p className="mitigation-value">{formatIntegerOnly(metricsData?.Total_Hydrogen_Shortfall, '-')} Kg/Yr</p>
            </div>
          </div>
          <div className="supply-right">
            <div className="tax-credits co2-wrap">
              <span className="section-logo">
                <img src={taxCreditLogo} alt="logo" />
              </span>
              <div className="suppy-credit-wrap">
                <p className="credits-value">${formatIntegerOnly(metricsData?.Local_Tax_Credits, '-')}</p>
                <span className="credits-des">Local Tax Credits</span>
              </div>
            </div>
            <div className="carbon-tax co2-wrap">
              <span className="section-logo">
                <img src={carbonTaxLogo} alt="logo" />
              </span>
              <div className="suppy-credit-wrap">
                <p className="credits-value">${formatIntegerOnly(metricsData?.Carbon_Tax, '-')}</p>
                <span className="credits-des">Carbon Tax</span>
              </div>
            </div>
          </div>
          <div className="supply-nvp">
            <div className="nvp-left co2-wrap">
              <div className="tax-credits">
                <span className="section-logo nvp-sec-logo">
                  <img src={nvpLogo} alt="logo" />
                </span>
                <div className="suppy-credit-wrap npv-credit-wrap">
                  <p className="credits-value npv-value">NPV</p>
                  <span className="credits-des npv-des">
                    ${formatIntegerOnly(metricsData?.NPV, '-')}
                  </span>
                </div>
              </div>
            </div>
            <div className="nvp-right co2-wrap">
              <div className="tax-credits">
                <span className="section-logo nvp-sec-logo">
                  <img src={irrLogo} alt="logo" />
                </span>
                <div className="suppy-credit-wrap npv-credit-wrap">
                  <p className="credits-value npv-value">IRR</p>
                  <span className="credits-des npv-des">{metricsData?.IRR < NUMBER.N1 ? '-' : formatIntegerOnly(metricsData?.IRR, '-')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cost-wrap">
        <div className="cost-img-sec">
          <div className="cost-img-wrap">
            <div className="cost-img-inner-wrap">
              <img src={electricityGraphImg} alt="logo img" />
              <h3>Cost</h3>
            </div>
          </div>
          <div className="cost-text-wrap">
            <div className="cost-text-inner-wrap">
              <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                openModal('Capital Costs', capexBreakdown)}>
                  Total Capex
              </Button>
              <h2 className="cost-text-head">${formatIntegerOnly(metricsData?.Capex)}</h2>
            </div>
            <div className="cost-text-inner-wrap">
              <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                openModal('Opex/yr', opexBreakdown)}>
                  Opex/yr
              </Button>
              <h2 className="cost-text-head">${formatIntegerOnly(metricsData?.Opex)}</h2>
            </div>
            <div className="cost-text-inner-wrap">
              <Button className="mitigation-label geo-dropdown-btn btn-no-outline" onClick={() =>
                openModal('Period Cost/yr', periodCostBreakdown)}>
                  Period Cost/yr
              </Button>
              <h2 className="cost-text-head">${formatIntegerOnly(metricsData?.Period_value)}</h2>
            </div>
          </div>
        </div>
        <div className="cost-value-one">
          <span className="cost-value-head">LCOE</span>
          {metricsData?.LCOE_Total > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap ">
            <span className="mitigation-label">Total</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Total)}</p>
          </div>}
          {metricsData?.LCOE_Solar > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Solar</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Solar)}</p>
          </div>}
          {metricsData?.LCOE_Turbine > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Turbine</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Turbine)}</p>
          </div>}
          {metricsData?.LCOE_Battery > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Battery</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Battery)}</p>
          </div>}
          {metricsData?.LCOE_Diesel > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Diesel</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Diesel)}</p>
          </div>}
          {metricsData?.LCOE_Gas > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Natural Gas</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOE_Gas)}</p>
          </div>}
          {metricsData?.Fuel_Cell_LCOE > NUMBER.N0 && <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Fuel Cell</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.Fuel_Cell_LCOE)}</p>
          </div>}
        </div>
        <div className="cost-value-two">
          <span className="cost-value-head">LCOH</span>
          <div className="co2-inner-wrap cost-value-wrap">
            <span className="mitigation-label">Total</span>
            <p className="lcoe-value">${formatAndRoundOffNumber(metricsData?.LCOH)}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const ScopeEmissions = ({ classname }: any) => {
    // temp check to hide total emissions from gigadac scenarios, need to revert later
    const isGigadac = workbenchNodes.find((ele: any) => ele.data.class === GIGADAC);
    return (
      <div className={`co2-wrap mitigation-double-col scope-chart-wrap ${classname}`}>
        <h4 className="section-head">Carbon Emissions (mt)</h4>
        <div className="co2-inner-wrap">
          <p className="mitigation-label">
            Scope 1
          </p>
          <p className="mitigation-value">{formatAndRoundOffNumber(metricsData?.scope_1_emissions)}</p>
        </div>
        <div className="co2-inner-wrap">
          <p className="mitigation-label">
            Scope 2
          </p>
          <p className="mitigation-value">{formatAndRoundOffNumber(metricsData?.scope_2_emissions)}</p>
        </div>
        <div className="co2-inner-wrap">
          <p className="mitigation-label">
            Scope 3
          </p>
          <p className="mitigation-value">{formatAndRoundOffNumber(metricsData?.scope_3_emissions)}</p>
        </div>
        {!isGigadac &&
        <div className="co2-inner-wrap total-scope-wrap hydrogen-total">
          <p className="mitigation-label">
            Total Emissions
          </p>
          <p className="mitigation-value">{formatAndRoundOffNumber(metricsData?.Total_emissions)}</p>

          <div className='yearly-emissions'>
            <p className="mitigation-label">
              Average Yearly Emissions
            </p>
            <p className="mitigation-value">{formatAndRoundOffNumber(metricsData?.yearly_emissions ?? NUMBER.N0)}</p>
          </div>
        </div>}
      </div>
    );
  };

  return (
    <>
      {(projectType as string).toLowerCase() === POWER && powerData()}
      {(projectType as string).toLowerCase() === HYDROGEN && hydrogenData()}
      {(projectType as string).toLowerCase() === CCUS && ccusData()}
      {(projectType as string).toLowerCase() === GEO_THERMAL && <GeoThermalEconomics />}
      {modalData && <PopoverModal showPopOverModal={showPopOverModal}
        setShowPopOverModal={setShowPopOverModal}
        modalTitle={modalTitle} modalData={modalData} modalUnit={modalUnit} />
      }    </>
  );
};

export default EconomicsData;
