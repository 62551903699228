import React, { useRef } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { CARBON_SETTINGS_SCHEMA, formatNumberWithCommas, keyPressHandle, parseFormattedNumber } from '../../utils';
import { ICarbonSettings } from '../../constants';
import { FormikProps, Formik, ErrorMessage } from 'formik';
// import Select from 'react-select';
// import { CARBON_SETTINGS_SCHEMA } from '../../utils';
// import { GIGADAC, ICarbonSettings, itcBonusTypeOptionsForTax, MITICO, sequestrationOptionsForTax, taxCreditTypeOptionsForTax } from '../../constants';
// import { FormikProps, Formik } from 'formik';
// import { useAppSelector } from '../../redux/hooks';

interface IFormikProps {
  handleForm: (values: any) => void
  formData: any
  fieldChange: any
  loading: boolean
  hideModal: () => void
}

const CarbonSettings = (props: IFormikProps) => {
  // const { formData } = props;
  const formikRef = useRef<FormikProps<ICarbonSettings> | null>(null);
  // const workbenchNodes = useAppSelector(state => state.workbench.nodes);

  // const isMiticoObj = workbenchNodes.find((ele: any) => ele.data.class === MITICO);
  // const isGigadac = workbenchNodes.find((ele: any) => ele.data.class === GIGADAC);

  // const defaultDacValue = () => {
  //   if (isMiticoObj) {
  //     return false;
  //   } else if (isGigadac) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const initialValues: ICarbonSettings = {
    subsidies: props.formData?.subsidies || ''
    // is_pw_and_a: formData?.is_pw_and_a ?? false,
    // is_dac: formData?.is_dac ?? defaultDacValue(),
    // sequestration_type: formData?.sequestration_type ?? 'tertiary',
    // pc_scheme: formData?.pc_scheme ?? 'ptc',
    // itc_bonus_type: itcBonusTypeOptionsForTax,
    // is_domestic_content: formData?.is_domestic_content ?? false,
    // is_energy_community: formData?.is_energy_community ?? false,
    // is_ira: formData?.is_ira ?? true
  };

  return (
    <>
      <div className="tab-main-container">
        <div className="tab-main-wrapper">
          <div className="financial-tab carbon-tab">
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={CARBON_SETTINGS_SCHEMA}
              onSubmit={props.handleForm}
            >
              {({ values, errors, handleSubmit, setFieldValue, handleChange, handleBlur, touched, setFieldError, ...rest }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="setting-form-content object-form-wrap">
                    <div className="object-left-col row">
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formSubsidies"
                          className="postion_relative"
                        >
                          <Form.Label>Subsidies/Year<sup>*</sup></Form.Label>
                          <Form.Control
                            type='text'
                            value={formatNumberWithCommas(values?.subsidies)}
                            name='subsidies'
                            data-testid="subsidies"
                            required={true}
                            onChange={event => {
                              event.target.value = parseFormattedNumber(event.target.value);
                              handleChange(event);
                            }}
                            onKeyDown={keyPressHandle}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage className="error-msg" name="subsidies" component='span' />
                        </Form.Group>
                        {/* <div className="div-1">
                          <span className="supply-head">Project meets PW&A requirements </span>
                          <div className="checkbox-wrapper">
                            <Form.Check
                              type="radio"
                              id="is_pw_and_a-true"
                              label="True"
                              name="is_pw_and_a"
                              checked={values.is_pw_and_a}
                              onChange={() => {
                                setFieldValue('is_pw_and_a', true);
                              }}
                            />
                            <Form.Check
                              type="radio"
                              id="is_pw_and_a-false"
                              label="False"
                              name="is_pw_and_a"
                              checked={!values.is_pw_and_a}
                              onChange={() => {
                                setFieldValue('is_pw_and_a', false);
                              }}
                            />
                          </div>
                        </div> */}
                      </div>
                      {/* <div className="col-md-6">
                        <div className="div-1">
                          <span className="supply-head">Project meets DAC requirements </span>
                          <div className="checkbox-wrapper">
                            <Form.Check
                              type="radio"
                              id="is_dac-true"
                              label="True"
                              name="is_dac"
                              checked={values.is_dac}
                              onChange={() => {
                                setFieldValue('is_dac', true);
                              }}
                            />
                            <Form.Check
                              type="radio"
                              id="is_dac-false"
                              label="False"
                              name="is_dac"
                              checked={!values.is_dac}
                              onChange={() => {
                                setFieldValue('is_dac', false);
                              }}
                            />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="object-left-col row carbon-settings-form">
                        <div className="col-md-12">
                          <Form.Group
                            controlId="formSequestration"
                            className="postion_relative"
                          >
                            <Form.Label>45Q Compliance Type</Form.Label>
                            <Select
                              className="custom-select-wrp"
                              classNamePrefix="select"
                              isClearable={false}
                              name="sequestration_type"
                              value={sequestrationOptionsForTax.find(option => option.value === values.sequestration_type)}
                              options={sequestrationOptionsForTax}
                              hideSelectedOptions={false}
                              onChange={(e: any) => {
                                setFieldValue('sequestration_type', e?.value);
                              }}
                              formatOptionLabel={({ label }) =>
                                <span className='checkbox-label-power'>{label}</span>
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="formCreditType"
                            className="postion_relative"
                          >
                            <Form.Label>Tax Credit Type</Form.Label>
                            <Select
                              className="custom-select-wrp"
                              classNamePrefix="select"
                              isClearable={false}
                              name="pc_scheme"
                              value={taxCreditTypeOptionsForTax.find(option => option.value === values.pc_scheme)}
                              options={taxCreditTypeOptionsForTax}
                              hideSelectedOptions={false}
                              onChange={(e: any) => {
                                setFieldValue('pc_scheme', e?.value);
                              }}
                              formatOptionLabel={({ label }) =>
                                <span className='checkbox-label-power'>{label}</span>
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="formCreditType"
                            className="postion_relative"
                          >
                            <Form.Label>ITC Bonus Type</Form.Label>
                            <Select
                              className="custom-select-wrp"
                              classNamePrefix="select"
                              isClearable={false}
                              isMulti
                              name="itc_bonus_type"
                              value={itcBonusTypeOptionsForTax.filter(option =>
                                (values.is_domestic_content && option.value === 'is_domestic_content') ||
                              (values.is_energy_community && option.value === 'is_energy_community')
                              )}
                              options={itcBonusTypeOptionsForTax}
                              hideSelectedOptions={false}
                              onChange={(selectedOptions: any) => {
                                const selectedValues = {
                                  is_domestic_content: selectedOptions.some((option: any) => option.value === 'is_domestic_content'),
                                  is_energy_community: selectedOptions.some((option: any) => option.value === 'is_energy_community')
                                };
                                setFieldValue('itc_bonus_type', selectedValues);
                                setFieldValue('is_domestic_content', selectedValues.is_domestic_content);
                                setFieldValue('is_energy_community', selectedValues.is_energy_community);
                              }}
                              formatOptionLabel={({ label }) =>
                                <span className='checkbox-label-power'>{label}</span>
                              }
                            />
                          </Form.Group>
                          <span className="supply-head">Claim IRA Benefits</span>
                          <div className="checkbox-wrapper">
                            <Form.Check
                              type="radio"
                              id="is_ira-true"
                              label="True"
                              name="is_ira"
                              checked={values.is_ira}
                              onChange={() => {
                                setFieldValue('is_ira', true);
                              }}
                            />
                            <Form.Check
                              type="radio"
                              id="is_ira-false"
                              label="False"
                              name="is_ira"
                              checked={!values.is_ira}
                              onChange={() => {
                                setFieldValue('is_ira', false);
                              }}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="setting-btn-wrap">
                    <Button className="btn-no-outline" onClick={props.hideModal}>
                      Cancel
                    </Button>
                    <Button className="primary" type="submit">
                      {props.loading ? <Spinner /> : 'Save Changes'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarbonSettings;
