export const LABELS = {
  deleteAlertMessage: (type: string) => `Delete ${type}?`,
  discardAlertMessage: (type: string) => `Discard ${type}?`,
  DELETE_ALERT_TITLE: 'Delete Project?',
  CLEAR_BOARD_TITLE: 'Clear Entire Board?',
  CLEAR_LINK_TITLE: 'Clear Linking?',
  YES_DELETE: 'Yes, Remove',
  KEEP_IT_FOR_NOW: 'Keep it for now',
  YES_DISCARD: 'Yes, Discard',
  YES_START: 'Yes, Start',
  YES_PROCEED: 'Yes, Proceed',
  YES_CLEAR: 'Yes, Clear',
  STAY: 'Stay on Page',
  KEEP_CREATING: 'Cancel, Keep Creating',
  YES: 'Yes',
  CANCEL: 'Cancel',
  SAVE_AND_PROCEED: 'Save & Proceed',
  PROJECT_SEARCH_PLACEHOLDER: 'Search by Name',
  SCENARIO_SEARCH_PLACEHOLDER: 'Search by Scenario Name',
  SAVE_PROPERTIES: 'Save Properties',
  UPDATE: 'Update',
  DISCARD_UPDATES: 'Discard Updates?',
  DELETE_SCENARIO: 'Delete Scenario?',
  YES_DELETE_SCENARIO: 'Yes, Remove Scenario',
  NOT_NOW: 'Not Now',
  NOT_AUTHORISED: 'Not Authorized',
  CREATE_NEW_PROJECT: 'Create new Project',
  UNSAVED_CHANGES: 'Exit Workbench?',
  SAVE_CHANGES: 'Save Changes',
  DISCARD_CHANGES: 'Discard Changes',
  POWER_SUMMARY: 'Power Summary',
  REMOVE_USER: 'Remove User',
  ABATEMENT_LOCATION: 'Abatement Location',
  LOCATION: 'Location',
  CREATE_SCENARIO: 'CREATE_SCENARIO',
  ALL_SCENARIO_LIST: 'ALL_SCENARIO_LIST',
  CREATE_WITH_AI: 'CREATE_WITH_AI',
  DASHBOARD: 'DASHBOARD',
  CLEAR_CHAT_BOARD: 'Clear Chat Board',
  UNSAVED_EV_SIMULATION_CHANGES: 'Exit Simulation?',
  PROJECT_DELETED: 'Project is Deleted',
  NO_ACTIVITIES: 'You have no latest activities yet.',
  NO_ARCHIVE_ACTIVITIES: 'You have no archive activities yet.',
  DISCARD_CHAT: 'Discard Chat',
  DISCARD_CHAT_PAGE: 'If you discard this chat, all your progress will be lost.',
  CREATE_SCENARIO_WITH_SCRATCH: 'Create Scenario with scratch',
  START_APPROVAL: 'Start Approval Process?',
  ADD_TO_BASELINE: 'Add to Baseline?',
  ACTIVATE_USER: 'Activate User',
  DEACTIVATE_USER: 'Deactivate User',
  YES_DEACTIVATE: 'Yes, Deactivate',
  YES_ACTIVATE: 'Yes, Activate',
  DELETE_USER: 'Delete User',
  DELETE_OBJECT: 'Delete Object',
  BASELINE_SCENARIO_LIST: 'BASELINE_SCENARIO_LIST',
  ACTIVE: 'active',
  ACTIVATE_COMPANY: 'Activate Company',
  DEACTIVATE_COMPANY: 'Deactivate Company',
  DELETE_COMPANY: 'Delete Company',
  UNSHARE_SCENARIO: 'Un-share Scenario?',
  YES_UNSHARE: 'Yes, Un-share',
  DISCARD_CHANGE: 'Discard Changes?',
  CHANGE_RESPONSE: 'Change Response?',
  YES_CHANGE: 'Yes, Change',
  KEEP_RESPONSE: 'Keep Response'
};

export const FORMULA_TITLE = {
  TURBINE_HEIGHT: 'turbineHeight',
  SCHEDULE_DEMAND: 'Demand_Schedule'
};

export const COMPONENT_LABELS = {
  HYDROGEN_ACTUAL_SUPPLY: 'Hydrogen Supply Actual',
  GEO_THERMAL: 'Geo Thermal'
};
