import { IComponentProperties, IObjectList, IProerties } from '../constants';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { IPropertiesResponse } from '../redux/services/workbenchApis';
import { updateList } from '../redux/slices/ObjeectListSlice';
import { Edge, Node, useReactFlow } from 'reactflow';
import { updateNodes, updateSavedNodes, updateUnsavedChange } from '../redux/workbenchSlice';
import { getArrayPropertyValue, getPropertyNameWithUnit, getWorkbenchNodeData } from '../utils';

/**
 * @returns update the object list in the srote...
 */
export const useComponentProperties = () => {
  const objectList = useAppSelector(state => state.objectList.list);
  const workbenchNodes = useAppSelector(state => state.workbench.nodes);
  const workbenchSavedNodes = useAppSelector(state => state.workbench.savedNodes);
  const workbenchSettings = useAppSelector(state => state.WorkbenchSettings);
  const settingsData = useAppSelector(state => state.WorkbenchSettings);
  const dispatch = useAppDispatch();
  const { setNodes, setEdges, getNode, getNodes, getEdges } = useReactFlow();

  /**
   * function to update component properties data in object list store
   * @param res IPropertiesResponse
   */
  const updateObjectListData = (res: IPropertiesResponse) => {
    const updatedList = objectList.map((item: IObjectList) => {
      if (item.component === res.compoment_name) {
        return {
          ...item,
          properties: res.data
        };
      }
      return item;
    });
    dispatch(updateList(updatedList));
  };

  /**
   * @param nodeId selected Component id
   * @param propertyValue changed parameter values
   * @returns updated nodes array with changed parameters values and update the store as well.... called when user save the parameters...
   */
  const updateNodeProperties = async (nodeId: string, propertyValue: IComponentProperties, properties: IProerties[], isSim2?: boolean) => {
    return await new Promise((resolve, reject) => {
      let node: any = {};
      if (isSim2) {
        node = workbenchSavedNodes.find((ele: any) => ele.id === nodeId);
      } else {
        node = workbenchNodes.find((ele: any) => ele.id === nodeId);
      }
      const propertiesNames: IComponentProperties = {};
      const propertiesValues: IComponentProperties = {};
      let listOfTurbines: any = [];
      const updatedPropertyValue: any = {};
      properties.forEach((prop) => {
        propertiesNames[getPropertyNameWithUnit(prop)] = propertyValue[prop.formulaTitle] ?? '';
        if (Object.hasOwn(propertyValue, prop.formulaTitle)) {
          propertiesValues[prop.formulaTitle] = propertyValue[prop.formulaTitle];
          updatedPropertyValue[prop.formulaTitle] = propertyValue[prop.formulaTitle];
        }
        if (prop.list_of_turbines) {
          listOfTurbines = [...prop.list_of_turbines];
        }
      });
      const nodesToMap = isSim2 ? workbenchSavedNodes : workbenchNodes;
      const updatedWorkbenchNodes = nodesToMap.map((item: Node) => {
        if (item.id === node?.id) {
          return {
            ...node,
            data: {
              ...node?.data,
              properties: propertiesNames,
              propertiesValues: {
                ...node?.data.propertiesValues,
                ...propertiesValues
              },
              listOfTurbines,
              ...updatedPropertyValue
            }
          };
        }
        return item;
      });
      !isSim2 && dispatch(updateNodes(updatedWorkbenchNodes));
      dispatch(updateSavedNodes(updatedWorkbenchNodes));
      dispatch(updateUnsavedChange(false));
      setNodes(updatedWorkbenchNodes);
      resolve(updatedWorkbenchNodes);
    });
  };

  const updateComponentName = (nodeId: string, componentName: string) => {
    const node = getNode(nodeId) as Node;
    const updatedWorkbenchNodes = workbenchNodes.map((item: Node) => {
      if (item.id === node.id) {
        return {
          ...node,
          data: {
            ...node.data,
            componentDisplayName: componentName
          }
        };
      }
      return item;
    });
    dispatch(updateNodes(updatedWorkbenchNodes));
    dispatch(updateSavedNodes(updatedWorkbenchNodes));
    dispatch(updateUnsavedChange(true));
    setNodes(updatedWorkbenchNodes);
  };

  /**
   * @param res Component properties response
   * @returns Updated nodes with default parameters values and update the store as well.
   * @call when user drag and drop any component into workbench
   */
  const updateWorkbenchNode = (res: IPropertiesResponse) => {
    const node = getNode(res.node_id) as Node;
    const updatedWorkbenchNodes = workbenchNodes.map((item: Node) => {
      if (item.id === node?.id) {
        const {
          propertiesNames, propertiesValues, propertiesFormulaTitle,
          setPropertyValues, propertiesType, units, minMax, scheduleProperty
        } = getWorkbenchNodeData(res.data, settingsData);

        return {
          ...node,
          data: {
            ...node.data,
            propertiesType,
            units,
            minMax,
            properties: propertiesNames,
            propertiesValues,
            formulaTitle: propertiesFormulaTitle,
            scheduleProperty,
            ...setPropertyValues
          }
        };
      }
      return item;
    });
    dispatch(updateNodes(updatedWorkbenchNodes));
    setNodes(updatedWorkbenchNodes);
  };

  /** function to update only units and min max if changed from backend for an object
   * so that those changes can be reflected on sim2 form after we click on show properties
   * for that object **/
  const updateSim2Data = (res: IPropertiesResponse) => {
    const node = getNode(res.node_id) as Node;
    const updatedWorkbenchNodes = workbenchSavedNodes.map((item: Node) => {
      if (item.id === node?.id) {
        const {
          units, minMax
        } = getWorkbenchNodeData(res.data, settingsData);

        return {
          ...node,
          data: {
            ...node.data,
            units,
            minMax
          }
        };
      }
      return item;
    });
    dispatch(updateSavedNodes(updatedWorkbenchNodes));
  };

  /**
   * Function to delete nodes and all its descendants from the store...
   * @param nodeId string id of node
   */
  const deleteNodeAndDescendants = (nodeId: string) => {
    const Nodes = getNodes();
    const updatedNodes = Nodes.filter(node => {
      if (node.id === nodeId || isDescendant(node, nodeId, Nodes)) {
        return false; // Exclude the node and its descendants
      }
      return true;
    });

    setNodes(updatedNodes);
  };
  /**
   * Function to delete all its descendant nodes from the store...
   * @param nodeId string id of node
   */
  const deleteDescendantNodes = (nodeId: string) => {
    const Nodes = getNodes();
    const updatedNodes = Nodes.filter(node => {
      if (isDescendant(node, nodeId, Nodes)) {
        return false; // Exclude the node and its descendants
      }
      return true;
    });

    setNodes(updatedNodes);
  };

  const isDescendant = (node: Node, targetId: string, Nodes: Node[]): boolean => {
    if (node.id === targetId) {
      return false;
    };
    if (!node.data?.parentNode) {
      return false;
    };
    if (node.data?.parentNode === targetId) {
      return true;
    }; // Direct child of target node
    const parentNode = Nodes.find(n => n.id === node.data?.parentNode);
    return parentNode ? isDescendant(parentNode, targetId, Nodes) : false;
  };

  /**
   * Function to delete edges and all its descendant from the store...
   * @param edgeId string id of edge
   */
  const deleteEdgesAndDescendants = (edgeId: string) => {
    const Edges = getEdges();
    const updatedEdges = Edges.filter(edge => {
      if (edge.source === edgeId || edge.target === edgeId || isDescendantEdge(edge, edgeId, Edges)) {
        return false; // Exclude the edge and its descendants
      }
      return true;
    });

    setEdges(updatedEdges);
  };

  /**
   * Function to delete all its descendant edges from the store...
   * @param edgeId string id of edge
   */
  const deleteDescendantEdges = (edgeId: string) => {
    const Edges = getEdges();
    const updatedEdges = Edges.filter(edge => {
      if (isDescendantEdge(edge, edgeId, Edges)) {
        return false; // Exclude its descendants edges
      }
      return true;
    });

    setEdges(updatedEdges);
  };

  /**
   * Function to delete the current layer edges from the store...
   * @param edgeId string id of edge
   */
  const deleteCurrentLayerEdges = (parentId: string) => {
    const Edges = getEdges();
    const updatedEdges = Edges.filter(e => {
      const edge = {
        ...e,
        data: {
          ...e.data,
          parentNode: e.data?.parentNode ?? ''
        }
      };
      if (edge.data?.parentNode === parentId) {
        return false; // Exclude the edge and its descendants
      }
      return true;
    });

    setEdges(updatedEdges);
  };

  const isDescendantEdge = (edge: Edge, targetId: string, Edges: Edge[]): boolean => {
    if (edge.source === targetId) {
      return false;
    };
    if (!edge.data?.parentNode) {
      return false;
    };
    if (edge.data?.parentNode === targetId) {
      return true;
    }; // Direct edge child of target node
    const parentEdge = Edges.find(n => n.source === edge.data?.parentNode);
    return parentEdge ? isDescendantEdge(parentEdge, targetId, Edges) : false;
  };

  return {
    updateObjectListData,
    updateWorkbenchNode,
    updateSim2Data,
    updateNodeProperties,
    updateComponentName,
    deleteNodeAndDescendants,
    deleteDescendantNodes,
    deleteEdgesAndDescendants,
    deleteDescendantEdges,
    deleteCurrentLayerEdges
  };
};
