import { Edge, MarkerType, Position } from 'reactflow';
import { IDsiderNode } from './interface';
import { defaultCapexScheduleData, defaultPeriodCostScheduleData, defaultScheduleData, scheduleDataForHydrogenDemand } from './static';


export const POWER_SOURCES: Record<string, IDsiderNode> = {
  'turbine': {
    'width': 64,
    'height': 64,
    'id': 'Turbines_4129',
    'name': 'Turbines',
    'type': 'dsiderNode',
    'position': {
      'x': 22.72715719165103,
      'y': 242.4868603668562
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'turbine',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Turbines.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Turbines.svg',
      'componentDisplayName': 'Land Turbines',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Energy Supply',
      'capacityProperty': 'Power_Capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Capital_Cost': 'number',
        'LF': 'number',
        'Period_Cost': 'number',
        'Power_Capacity': 'number',
        'PwrRETransVCost': 'number',
        'takeOfPercent': 'number',
        'TransLoss': 'number',
        'turbineHeight': 'text',
        'PwrREAvail': 'number',
        'PwrREOut': 'number',
        'PwrREVCost': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Capital_Cost': '$/KW',
        'LF': '%',
        'Period_Cost': '$/year/KW',
        'Power_Capacity': 'KW',
        'PwrRETransVCost': '$/KW',
        'takeOfPercent': '%',
        'TransLoss': '%',
        'turbineHeight': 'm',
        'PwrREAvail': 'KW',
        'PwrREOut': 'KW',
        'PwrREVCost': '$/KW'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Capital Cost ($/KW)': 1800,
        'Period Cost ($/year/KW)': 40,
        'Power Capacity (KW)': 6000,
        'PwrRETransVCost ($/KW)': 0,
        'Scope 1 Intensity (kg CO2e/kWh)': 0,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0.13,
        'Take off Percent (Percentage (%))': 80,
        'Transmission Loss Factor (Percentage (%))': 0,
        'Turbine Model & Height (m)': 'Windflow.500,150',
        'Variable cost of energy ($/KW)': 0
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capital Cost': 'Capital_Cost',
        'Load factor': 'LF',
        'Period Cost': 'Period_Cost',
        'Power Capacity': 'Power_Capacity',
        'PwrRETransVCost': 'PwrRETransVCost',
        'Take off Percent': 'takeOfPercent',
        'Transmission Loss Factor': 'TransLoss',
        'Turbine Model & Height': 'turbineHeight',
        'Turbines Available': 'PwrREAvail',
        'Turbines Produced': 'PwrREOut',
        'Variable cost of energy': 'PwrREVCost'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Capital_Cost': defaultCapexScheduleData('1800'),
        'LF': 0,
        'Period_Cost': defaultPeriodCostScheduleData('40'),
        'Power_Capacity': 6000,
        'PwrRETransVCost': scheduleDataForHydrogenDemand('0'),
        'takeOfPercent': 80,
        'TransLoss': 0,
        'turbineHeight': 'Windflow.500,150',
        'PwrREAvail': 0,
        'PwrREOut': 0,
        'PwrREVCost': 0,
        'comments': [],
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0.13
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Capital_Cost': 1800,
      'LF': 0,
      'Period_Cost': 40,
      'Power_Capacity': 6000,
      'PwrRETransVCost': 0,
      'takeOfPercent': 80,
      'TransLoss': 0,
      'turbineHeight': 'Windflow.500,150',
      'PwrREAvail': 0,
      'PwrREOut': 0,
      'PwrREVCost': 0,
      'comments': [],
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0.13
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 22.72715719165103,
      'y': 242.4868603668562
    },
    'hidden': false
  },
  'solar_panel': {
    'width': 64,
    'height': 64,
    'id': 'Solar_Panel_7328',
    'name': 'Solar Panel',
    'type': 'dsiderNode',
    'position': {
      'x': 24.82448599102372,
      'y': 130.28982743925246
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'Solar',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Solar+Panel.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Solar+Panel.svg',
      'componentDisplayName': 'Solar Panel',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Energy Supply',
      'capacityProperty': 'Power_Capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Capital_Cost': 'number',
        'LF': 'number',
        'Period_Cost': 'number',
        'Power_Capacity': 'number',
        'PwrRETransVCost': 'number',
        'PwrREAvail': 'number',
        'PwrREOut': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'takeOfPercent': 'number',
        'TransLoss': 'number',
        'PwrREVCost': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Capital_Cost': '$/KW',
        'LF': 'Percentage (%)',
        'Period_Cost': '$/year/KW',
        'Power_Capacity': 'KW',
        'PwrRETransVCost': '$/KW',
        'PwrREAvail': 'KW',
        'PwrREOut': 'KW',
        'scope_1_opex': 'kg CO2e/kWh',
        'scope_2_opex': 'kg CO2e/kWh',
        'scope_3_capex': 'kg CO2e',
        'takeOfPercent': 'Percentage (%)',
        'TransLoss': 'Percentage (%)',
        'PwrREVCost': '$/KW'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Capital Cost ($/KW)': 1300,
        'Load factor (Percentage (%))': 0,
        'Period Cost ($/year/KW)': 18,
        'Power Capacity (KW)': 3000,
        'Power Transport cost ($/KW)': 0,
        'Renewable Power Available (KW)': 0,
        'Renewable Power Produced (KW)': 0,
        'Scope 1 Intensity (kg CO2e/kWh)': 0,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0.43,
        'Take off Percent (Percentage (%))': 80,
        'Transmission Loss Factor (Percentage (%))': 0,
        'Variable cost of energy ($/KW)': 0
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capital Cost': 'Capital_Cost',
        'Load factor': 'LF',
        'Period Cost': 'Period_Cost',
        'Power Capacity': 'Power_Capacity',
        'Power Transport cost': 'PwrRETransVCost',
        'Renewable Power Available': 'PwrREAvail',
        'Renewable Power Produced': 'PwrREOut',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Take off Percent': 'takeOfPercent',
        'Transmission Loss Factor': 'TransLoss',
        'Variable cost of energy': 'PwrREVCost'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Capital_Cost': defaultCapexScheduleData('1300'),
        'LF': 0,
        'Period_Cost': defaultPeriodCostScheduleData('18'),
        'Power_Capacity': 3000,
        'PwrRETransVCost': 0.0,
        'PwrREAvail': 0,
        'PwrREOut': 0,
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0.43,
        'takeOfPercent': 80,
        'TransLoss': 0,
        'PwrREVCost': scheduleDataForHydrogenDemand('0')
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Capital_Cost': 1300,
      'LF': 0,
      'Period_Cost': 18,
      'Power_Capacity': 3000,
      'PwrRETransVCost': 0.0,
      'PwrREAvail': 0,
      'PwrREOut': 0,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0.43,
      'takeOfPercent': 80,
      'TransLoss': 0,
      'PwrREVCost': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 24.82448599102372,
      'y': 130.28982743925246
    },
    'hidden': false
  },
  'battery': {
    'width': 64,
    'height': 64,
    'id': 'Battery_5278',
    'name': 'Battery',
    'type': 'dsiderNode',
    'position': {
      'x': 454.2564306026136,
      'y': 34.554062721459275
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'battery',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Battery.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Battery.svg',
      'componentDisplayName': 'Battery',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Battery Capacity',
      'capacityProperty': 'BatCapStart',
      'powerCategory': 'mixed',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Battery Capital Cost': 'number',
        'Battery Period Cost': 'number',
        'ChargeVCost': 'number',
        'DischargeVCost': 'number',
        'minimum_charge_proportion': 'number',
        'MaxBatDCharge': 'number',
        'BatCapStart': 'number',
        'PwrBatInitCharge': 'number',
        'PwrBatMinCharge': 'number',
        'LossBatStore': 'number',
        'PwrBatTgtCharge': 'number',
        'LossBatOut': 'number',
        'BatCapDegrade': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Battery Capital Cost': '$/kWh',
        'Battery Period Cost': '$/year/kWh',
        'ChargeVCost': '$/kWh',
        'DischargeVCost': '$/kWh',
        'minimum_charge_proportion': '%',
        'MaxBatDCharge': '%',
        'BatCapStart': 'kWh',
        'PwrBatInitCharge': '%',
        'PwrBatMinCharge': '%',
        'LossBatStore': '%/Month',
        'PwrBatTgtCharge': '%',
        'LossBatOut': '%',
        'BatCapDegrade': '%'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Battery Capital Cost ($/kWh)': 1300,
        'Battery Period Cost ($/year/kWh)': 45,
        'Charge Cost ($/kWh)': 0,
        'Discharge Cost ($/kWh)': 0,
        'Hourly Charge Percent (Percentage (%))': 20,
        'Hourly Discharge Percent (Percentage (%))': 20,
        'Initial Capacity (kWh)': 3000,
        'Initial Charge Percent (Percentage (%))': 80,
        'Minimum Fill Percent (Percentage (%))': 80,
        'Monthly Self Discharge Percent (%/Month)': 0.5,
        'Scope 1 Intensity (kg CO2e/kWh)': 0,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0.033,
        'Target Fill Percent (Percentage (%))': 80,
        'Transmission Loss Out (Percentage (%))': 0,
        'Yearly Capacity Degrade Factor (Percentage (%))': 1.5
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Battery Capital Cost': 'Battery Capital Cost',
        'Battery Period Cost': 'Battery Period Cost',
        'Charge Cost': 'ChargeVCost',
        'Discharge Cost': 'DischargeVCost',
        'Hourly Charge Percent': 'minimum_charge_proportion',
        'Hourly Discharge Percent': 'MaxBatDCharge',
        'Initial Capacity': 'BatCapStart',
        'Initial Charge Percent': 'PwrBatInitCharge',
        'Minimum Fill Percent': 'PwrBatMinCharge',
        'Monthly Self Discharge Percent': 'LossBatStore',
        'Target Fill Percent': 'PwrBatTgtCharge',
        'Transmission Loss Out': 'LossBatOut',
        'Yearly Capacity Degrade Factor': 'BatCapDegrade'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Battery Capital Cost': defaultCapexScheduleData('1300'),
        'Battery Period Cost': defaultPeriodCostScheduleData('45'),
        'ChargeVCost': scheduleDataForHydrogenDemand('0'),
        'DischargeVCost': scheduleDataForHydrogenDemand('0'),
        'minimum_charge_proportion': 20,
        'MaxBatDCharge': 20,
        'BatCapStart': 3000,
        'PwrBatInitCharge': 80,
        'PwrBatMinCharge': 80,
        'LossBatStore': 0.5,
        'PwrBatTgtCharge': 80,
        'LossBatOut': 0,
        'BatCapDegrade': 1.5,
        'comments': [],
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0.033
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Battery Capital Cost': 1300,
      'Battery Period Cost': 45,
      'ChargeVCost': 0,
      'DischargeVCost': 0,
      'minimum_charge_proportion': 20,
      'MaxBatDCharge': 20,
      'BatCapStart': 3000,
      'PwrBatInitCharge': 80,
      'PwrBatMinCharge': 80,
      'LossBatStore': 0.5,
      'PwrBatTgtCharge': 80,
      'LossBatOut': 0,
      'BatCapDegrade': 1.5,
      'comments': [],
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0.033
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 454.2564306026136,
      'y': 34.554062721459275
    },
    'hidden': false
  },
  'Grid': {
    'width': 64,
    'height': 64,
    'id': 'Grid_8207',
    'name': 'Grid',
    'type': 'dsiderNode',
    'position': {
      'x': 454.16419021804677,
      'y': 425.77096530969374
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'Grid',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Grid.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Grid.svg',
      'componentDisplayName': 'Grid',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Grid Capacity',
      'capacityProperty': [
        'P_max_out, P_max_in'
      ],
      'powerCategory': 'mixed',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Max_Power_Available': 'number',
        'P_max_out': 'number',
        'P_max_in': 'number',
        'Price': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number',
        'selling_price': 'number',
        'loss_in': 'number',
        'loss_out': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Max_Power_Available': 'KW',
        'P_max_out': 'KW',
        'P_max_in': 'KW',
        'Price': '$/KW',
        'scope_1_opex': 'kg CO2e/kWh',
        'scope_2_opex': 'kg CO2e/kWh',
        'scope_3_capex': 'kg CO2e',
        'selling_price': '$/KW',
        'loss_in': 'Percentage (%)',
        'loss_out': 'Percentage (%)'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Max Power Available (KW)': 100000,
        'Max Power From Grid (KW)': 100000,
        'Max Power To Grid (KW)': 0,
        'Purchase Price ($/KW)': 0.10,
        'Scope 1 Intensity (kg CO2e/kWh)': 0,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0,
        'Selling Price ($/KW)': 0,
        'loss in (Percentage (%))': 0.01,
        'loss out (Percentage (%))': 0.01
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Max Power Available': 'Max_Power_Available',
        'Max Power From Grid': 'P_max_out',
        'Max Power To Grid': 'P_max_in',
        'Purchase Price': 'Price',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex',
        'Selling Price': 'selling_price',
        'loss in': 'loss_in',
        'loss out': 'loss_out'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Max_Power_Available': 100000,
        'P_max_out': 100000,
        'P_max_in': 0,
        'Price': scheduleDataForHydrogenDemand('0.10'),
        'scope_1_opex': 0,
        'scope_2_opex': 0,
        'scope_3_capex': 0,
        'selling_price': scheduleDataForHydrogenDemand('0'),
        'loss_in': 0.01,
        'loss_out': 0.01
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Max_Power_Available': 100000,
      'P_max_out': 100000,
      'P_max_in': 0,
      'Price': 0.10,
      'scope_1_opex': 0,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'selling_price': 0,
      'loss_in': 0.01,
      'loss_out': 0.01,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 454.16419021804677,
      'y': 425.77096530969374
    },
    'hidden': false
  },
  'natural_gas': {
    'width': 64,
    'height': 64,
    'id': 'Natural_Gas_3631',
    'name': 'Natural Gas',
    'type': 'dsiderNode',
    'position': {
      'x': 24.003130147654133,
      'y': 362.62636179709716
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'natural_gas',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/gas.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/gas.svg',
      'componentDisplayName': 'Natural Gas',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Energy Supply',
      'capacityProperty': 'capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'abatement_location': 'text',
        'location': 'text',
        'Capital_Cost': 'number',
        'period_cost': 'number',
        'fuel_price': 'number',
        'capacity': 'number',
        'transmission_loss_proportion': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number'
      },
      'units': {
        'abatement_location': '',
        'location': '',
        'Capital_Cost': '$/KW',
        'period_cost': '$/year/KW',
        'fuel_price': '$/Mcf',
        'capacity': 'KVa',
        'transmission_loss_proportion': 'Percentage (%)',
        'scope_1_opex': 'kg CO2e/kWh',
        'scope_2_opex': 'kg CO2e/kWh',
        'scope_3_capex': 'kg CO2e'
      },
      'properties': {
        'Abatement Location': '30.24, -97.74',
        'Location': '30.24, -97.74',
        'Capital Cost ($/KW)': 20,
        'Period Cost ($/year/KW)': 20,
        'Fuel Price ($/Mcf)': 3.5,
        'Capacity (KVa)': 100,
        'Transmission Loss Factor (Percentage (%))': 0,
        'Scope 1 Intensity (kg CO2e/kWh)': 0.181,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0
      },
      'formulaTitle': {
        'Abatement Location': 'abatement_location',
        'Location': 'location',
        'Capital Cost': 'Capital_Cost',
        'Period Cost': 'period_cost',
        'Fuel Price': 'fuel_price',
        'Capacity': 'capacity',
        'Transmission Loss Factor': 'transmission_loss_proportion',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex'
      },
      'propertiesValues': {
        'abatement_location': '29.749907, -95.358421',
        'location': '29.749907, -95.358421',
        'Capital_Cost': defaultCapexScheduleData('20'),
        'period_cost': defaultPeriodCostScheduleData('20'),
        'fuel_price': scheduleDataForHydrogenDemand('3.5'),
        'capacity': 100,
        'transmission_loss_proportion': 0,
        'scope_1_opex': 0.181,
        'scope_2_opex': 0,
        'scope_3_capex': 0
      },
      'abatement_location': '29.749907, -95.358421',
      'location': '29.749907, -95.358421',
      'Capital_Cost': 20,
      'period_cost': 20,
      'fuel_price': 3.5,
      'capacity': 100,
      'transmission_loss_proportion': 0,
      'scope_1_opex': 0.181,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 24.003130147654133,
      'y': 362.62636179709716
    },
    'hidden': false
  },
  'diesel': {
    'id': 'Diesel_3486',
    'name': 'Diesel',
    'type': 'dsiderNode',
    'position': {
      'x': 23.980578600084414,
      'y': 16.62052049755033
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'diesel',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/diesel.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/diesel.svg',
      'componentDisplayName': 'Diesel',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Energy Supply',
      'capacityProperty': 'capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'abatement_location': 'text',
        'location': 'text',
        'Capital_Cost': 'number',
        'period_cost': 'number',
        'fuel_price': 'number',
        'capacity': 'number',
        'transmission_loss_proportion': 'number',
        'scope_1_opex': 'number',
        'scope_2_opex': 'number',
        'scope_3_capex': 'number'
      },
      'units': {
        'abatement_location': '',
        'location': '',
        'Capital_Cost': '$/KW',
        'period_cost': '$/year/KW',
        'fuel_price': '$/gallon',
        'capacity': 'KVa',
        'transmission_loss_proportion': 'Percentage (%)',
        'scope_1_opex': 'kg CO2e/kWh',
        'scope_2_opex': 'kg CO2e/kWh',
        'scope_3_capex': 'kg CO2e'
      },
      'properties': {
        'Abatement Location': '30.24, -97.74',
        'Location': '30.24, -97.74',
        'Capital Cost ($/KW)': 20,
        'Period Cost ($/year/KW)': 20,
        'Fuel Price ($/gallon)': 3.5,
        'Capacity (KVa)': 100,
        'Transmission Loss Factor (Percentage (%))': 0,
        'Scope 1 Intensity (kg CO2e/kWh)': 0.253,
        'Scope 2 Intensity (kg CO2e/kWh)': 0,
        'Scope 3 Emissions (kg CO2e)': 0
      },
      'formulaTitle': {
        'Abatement Location': 'abatement_location',
        'Location': 'location',
        'Capital Cost': 'Capital_Cost',
        'Period Cost': 'period_cost',
        'Fuel Price': 'fuel_price',
        'Capacity': 'capacity',
        'Transmission Loss Factor': 'transmission_loss_proportion',
        'Scope 1 Intensity': 'scope_1_opex',
        'Scope 2 Intensity': 'scope_2_opex',
        'Scope 3 Emissions': 'scope_3_capex'
      },
      'propertiesValues': {
        'abatement_location': '29.749907, -95.358421',
        'location': '29.749907, -95.358421',
        'Capital_Cost': defaultCapexScheduleData('20'),
        'period_cost': defaultPeriodCostScheduleData('20'),
        'fuel_price': scheduleDataForHydrogenDemand('3.5'),
        'capacity': 100,
        'transmission_loss_proportion': 0,
        'scope_1_opex': 0.253,
        'scope_2_opex': 0,
        'scope_3_capex': 0
      },
      'abatement_location': '29.749907, -95.358421',
      'location': '29.749907, -95.358421',
      'Capital_Cost': 20,
      'period_cost': 20,
      'fuel_price': 3.5,
      'capacity': 100,
      'transmission_loss_proportion': 0,
      'scope_1_opex': 0.253,
      'scope_2_opex': 0,
      'scope_3_capex': 0,
      'comments': []
    },
    'selected': true,
    'dragging': false,
    'width': 64,
    'height': 64,
    'positionAbsolute': {
      'x': 23.980578600084414,
      'y': 16.62052049755033
    }
  },
  'ElectricPowerDemand': {
    'width': 64,
    'height': 64,
    'id': 'Electric_Power_Demand_6385',
    'name': 'Electric Power Demand',
    'type': 'dsiderNode',
    'position': {
      'x': 627.062836580373,
      'y': 244.80372474745263
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'ElectricPowerDemand',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Electric+Power+Demand.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Electric+Power+Demand.svg',
      'componentDisplayName': 'Electric Power Demand',
      'no_of_inputs': 1,
      'no_of_outputs': 0,
      'sensitivityFormCategory': 'Energy Demand',
      'capacityProperty': 'Demand',
      'powerCategory': 'demander',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [],
      'defaultType': 'input',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Demand': 'number',
        'selling_price': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Demand': 'KW',
        'selling_price': '$/KW'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Demand (KW)': 5000,
        'Purchase Price ($/KW)': 0.1
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Demand': 'Demand',
        'Purchase Price': 'selling_price'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'Demand': scheduleDataForHydrogenDemand('5000'),
        'selling_price': scheduleDataForHydrogenDemand('0.1')
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'Demand': 5000,
      'selling_price': 0.1,
      'comments': []
    },
    'selected': true,
    'dragging': false,
    'positionAbsolute': {
      'x': 627.062836580373,
      'y': 244.80372474745263
    },
    'hidden': false
  },
  'ElectricityLB': {
    'width': 64,
    'height': 64,
    'id': 'Electricity_LB_2698',
    'name': 'Electricity LB',
    'type': 'dsiderNode',
    'position': {
      'x': 308.22851998283454,
      'y': 244.1489290508046
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'ElectricityLB',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Electricity+LB.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Electricity+LB.svg',
      'componentDisplayName': 'Electricity LB',
      'no_of_inputs': 5,
      'no_of_outputs': 3,
      'sensitivityFormCategory': 'ElectricityLB Capacity',
      'capacityProperty': null,
      'powerCategory': '',
      'input_node_arr': [
        1,
        2,
        3,
        4,
        5
      ],
      'output_node_arr': [
        1,
        2,
        3
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text'
      },
      'units': {
        'location': '',
        'abatement_location': ''
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74'
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74'
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 308.22851998283454,
      'y': 244.1489290508046
    },
    'hidden': false
  },
  'LB': {
    'width': 64,
    'height': 64,
    'id': 'LB_1176',
    'name': 'LB',
    'type': 'dsiderNode',
    'position': {
      'x': 656.4984886177892,
      'y': 263.14226490858005
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'hydrogenLB',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Hydrogen+LB.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Hydrogen+LB.svg',
      'componentDisplayName': 'LB',
      'no_of_inputs': 3,
      'no_of_outputs': 3,
      'sensitivityFormCategory': '',
      'capacityProperty': '',
      'powerCategory': null,
      'input_node_arr': [
        1,
        2,
        3
      ],
      'output_node_arr': [
        1,
        2,
        3
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'Residual_Hydrogen': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'Residual_Hydrogen': '%'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Residual Hydrogen (%)': 0
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Residual Hydrogen': 'Residual_Hydrogen'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74',
        'Residual_Hydrogen': 0
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'Residual_Hydrogen': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 656.4984886177892,
      'y': 263.14226490858005
    },
    'hidden': false
  },
  'electrolyzer': {
    'width': 64,
    'height': 64,
    'id': 'Electrolyzer_4512',
    'name': 'Electrolyzer',
    'type': 'dsiderNode',
    'position': {
      'x': 476.912384417069,
      'y': 252.4000015258789
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'electrolyzer',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Electrolyzer.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Electrolyzer.svg',
      'componentDisplayName': 'Electrolyzer',
      'no_of_inputs': 2,
      'no_of_outputs': 2,
      'sensitivityFormCategory': 'Green Hydrogen',
      'capacityProperty': 'capacity',
      'powerCategory': 'reactor',
      'input_node_arr': [
        1,
        2
      ],
      'output_node_arr': [
        1,
        2
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'capex': 'number',
        'efficiency_rate': 'number',
        'conversion_factor': 'number',
        'capacity': 'number',
        'MaxElDecline': 'number',
        'MaxElRamp': 'number',
        'minimum_required_power': 'number',
        'opex': 'number',
        'period_cost': 'number',
        'yearly_degradation_rate': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'capex': '$/KW',
        'efficiency_rate': '%',
        'conversion_factor': 'Kg/KW',
        'capacity': 'KW',
        'MaxElDecline': 'KW/H',
        'MaxElRamp': 'KW/H',
        'minimum_required_power': '%',
        'opex': '$/KW',
        'period_cost': '$/year/KW',
        'yearly_degradation_rate': '%'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Capital Cost ($/KW)': 890,
        'Conversion Efficiency (%)': 60,
        'Conversion Factor (Kg/KW)': 0.0174,
        'Initial Capacity (KW)': 100000,
        'Max Ramp Down % of Capacity (KW/H)': 150,
        'Max Ramp Up % of Capacity (KW/H)': 150,
        'Min Required Load (%)': 10,
        'Operating Cost ($/KW)': 0,
        'Period Cost ($/year/KW)': 44.5,
        'Yearly Degrade Factor (%)': 1
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capital Cost': 'capex',
        'Conversion Efficiency': 'efficiency_rate',
        'Conversion Factor': 'conversion_factor',
        'Initial Capacity': 'capacity',
        'Max Ramp Down % of Capacity': 'MaxElDecline',
        'Max Ramp Up % of Capacity': 'MaxElRamp',
        'Min Required Load': 'minimum_required_power',
        'Operating Cost': 'opex',
        'Period Cost': 'period_cost',
        'Yearly Degrade Factor': 'yearly_degradation_rate'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74',
        'capex': defaultCapexScheduleData('890'),
        'efficiency_rate': 60,
        'conversion_factor': 0.0174,
        'capacity': 100000,
        'MaxElDecline': 150,
        'MaxElRamp': 150,
        'minimum_required_power': 10,
        'opex': scheduleDataForHydrogenDemand('0'),
        'period_cost': defaultPeriodCostScheduleData('44.5'),
        'yearly_degradation_rate': 1
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'capex': 890,
      'efficiency_rate': 60,
      'conversion_factor': 0.0174,
      'capacity': 100000,
      'MaxElDecline': 150,
      'MaxElRamp': 150,
      'minimum_required_power': 10,
      'opex': 0,
      'period_cost': 44.5,
      'yearly_degradation_rate': 1,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 476.912384417069,
      'y': 252.4000015258789
    },
    'hidden': false
  },
  'hydrogen_storage': {
    'width': 64,
    'height': 64,
    'id': 'Hydrogen_Storage_2459',
    'name': 'Hydrogen Storage',
    'type': 'dsiderNode',
    'position': {
      'x': 782.8951832278881,
      'y': 70.98912849102061
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'hydrogen_storage',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Green+H2+Storage.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Green+H2+Storage.svg',
      'componentDisplayName': 'Hydrogen Storage',
      'no_of_inputs': 1,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Green Hydrogen',
      'capacityProperty': 'capacity',
      'powerCategory': 'mixed',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [
        1
      ],
      'defaultType': 'default',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'capacity': 'number',
        'capex': 'number',
        'charge_cost': 'number',
        'discharge_cost': 'number',
        'initial_capacity': 'number',
        'initial_fill_proportion': 'number',
        'period_cost': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'capacity': 'Kg',
        'capex': '$/Kg',
        'charge_cost': '$/kg',
        'discharge_cost': '$/kg',
        'initial_capacity': 'Kg',
        'initial_fill_proportion': '%',
        'period_cost': '$/year/kg',
        'transmission_loss_proportion': '%'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'Capacity (Kg)': 10000,
        'Capital Cost ($/Kg)': 850,
        'Charge Cost ($/kg)': 0,
        'Discharge cost ($/kg)': 0,
        'Initial Capacity (Kg)': 500,
        'Initial Fill Percent (%)': 80,
        'Period Cost ($/year/kg)': 17,
        'Transmission Loss Percent (%)': 0
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capacity': 'capacity',
        'Capital Cost': 'capex',
        'Charge Cost': 'charge_cost',
        'Discharge cost': 'discharge_cost',
        'Initial Capacity': 'initial_capacity',
        'Initial Fill Percent': 'initial_fill_proportion',
        'Period Cost': 'period_cost',
        'Transmission Loss Percent': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74',
        'capacity': 10000,
        'capex': defaultCapexScheduleData('850'),
        'charge_cost': scheduleDataForHydrogenDemand('0'),
        'discharge_cost': scheduleDataForHydrogenDemand('0'),
        'initial_capacity': 500,
        'initial_fill_proportion': 80,
        'period_cost': defaultPeriodCostScheduleData('17'),
        'transmission_loss_proportion': 0
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'capacity': 10000,
      'capex': 850,
      'charge_cost': 0,
      'discharge_cost': 0,
      'initial_capacity': 500,
      'initial_fill_proportion': 80,
      'period_cost': 17,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 782.8951832278881,
      'y': 70.98912849102061
    },
    'hidden': false
  },
  'external_hydrogen': {
    'width': 64,
    'height': 64,
    'id': 'External_Gray_Supplier_7804',
    'name': 'External Gray Supplier',
    'type': 'dsiderNode',
    'position': {
      'x': 654.1834980484191,
      'y': 420.5951767933706
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'external_hydrogen',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/External+Gray+Supply.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/External+Gray+Supply.svg',
      'componentDisplayName': 'External Hydrogen Supplier',
      'no_of_inputs': 0,
      'no_of_outputs': 1,
      'sensitivityFormCategory': 'Green Hydrogen',
      'capacityProperty': 'capacity',
      'powerCategory': 'supplier',
      'input_node_arr': [],
      'output_node_arr': [
        1
      ],
      'defaultType': 'output',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'capacity': 'number',
        'carbon_intensity': 'number',
        'price': 'number',
        'transmission_loss_proportion': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'capacity': 'Kg',
        'carbon_intensity': 'Kg CO2e/Kg H2',
        'price': '$/kg',
        'transmission_loss_proportion': '%'
      },
      'properties': {
        'Location': '29.749907, -95.358421',
        'Abatement Location': '29.749907, -95.358421',
        'Capacity (Kg)': 5000,
        'Carbon Intensity (Kg CO2e/Kg H2)': 0.01,
        'Purchase Price ($/kg)': 5,
        'Transmission Loss (%)': 0
      },
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'Capacity': 'capacity',
        'Carbon Intensity': 'carbon_intensity',
        'Purchase Price': 'price',
        'Transmission Loss': 'transmission_loss_proportion'
      },
      'propertiesValues': {
        'location': '29.749907, -95.358421',
        'abatement_location': '29.749907, -95.358421',
        'capacity': 5000,
        'carbon_intensity': 0.01,
        'price': scheduleDataForHydrogenDemand('5'),
        'transmission_loss_proportion': 0
      },
      'location': '29.749907, -95.358421',
      'abatement_location': '29.749907, -95.358421',
      'capacity': 5000,
      'carbon_intensity': 0.01,
      'price': 5,
      'transmission_loss_proportion': 0,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 654.1834980484191,
      'y': 420.5951767933706
    },
    'hidden': false
  },
  'HydrogenDemand': {
    'width': 64,
    'height': 64,
    'id': 'Hydrogen_Demand_2319',
    'name': 'Hydrogen Demand',
    'type': 'dsiderNode',
    'position': {
      'x': 918.9848861778912,
      'y': 262.76011581787395
    },
    'sourcePosition': 'right' as Position,
    'targetPosition': 'left' as Position,
    'data': {
      'parentNode': '',
      'class': 'hydrogen',
      'imageS3url': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-white/Mobility.svg',
      'imageS3urlDark': 'https://dsiderinc-bucket.s3.amazonaws.com/object-icons-dark/Mobility.svg',
      'componentDisplayName': 'Hydrogen Demand',
      'no_of_inputs': 1,
      'no_of_outputs': 0,
      'sensitivityFormCategory': 'Green Hydrogen',
      'capacityProperty': 'hydrogen_schedule',
      'powerCategory': 'demander',
      'input_node_arr': [
        1
      ],
      'output_node_arr': [],
      'defaultType': 'input',
      'propertiesType': {
        'location': 'text',
        'abatement_location': 'text',
        'hydrogen_schedule': 'text',
        'price': 'number'
      },
      'units': {
        'location': '',
        'abatement_location': '',
        'hydrogen_schedule': 'kg',
        'price': '$/kg'
      },
      'properties': {
        'Location': '30.24, -97.74',
        'Abatement Location': '30.24, -97.74',
        'H2 Demand Schedule (kg)': 'Yearly',
        'H2 Price ($/kg)': 1
      },
      'scheduleData': {},
      'scheduleType': '',
      'formulaTitle': {
        'Location': 'location',
        'Abatement Location': 'abatement_location',
        'H2 Demand Schedule': 'hydrogen_schedule',
        'H2 Price': 'price'
      },
      'propertiesValues': {
        'location': '30.24, -97.74',
        'abatement_location': '30.24, -97.74',
        'hydrogen_schedule': scheduleDataForHydrogenDemand('500'),
        'price': scheduleDataForHydrogenDemand('1')
      },
      'location': '30.24, -97.74',
      'abatement_location': '30.24, -97.74',
      'hydrogen_schedule': 500,
      'price': 1,
      'comments': []
    },
    'selected': false,
    'dragging': false,
    'positionAbsolute': {
      'x': 918.9848861778912,
      'y': 262.76011581787395
    },
    'hidden': false
  }
};

export const POWER_SOURCES_EDGES: Record<string, Edge[]> = {
  'turbine': [
    {
      'source': 'Turbines_4129',
      'sourceHandle': 'output-1-Turbines_4129',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-3-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      'selected': false,
      'id': 'reactflow__edge-Turbines_4129output-1-Turbines_4129-Electricity_LB_2698input-3-Electricity_LB_2698'
    }
  ],
  'solar_panel': [
    {
      'source': 'Solar_Panel_7328',
      'sourceHandle': 'output-1-Solar_Panel_7328',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-2-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      'selected': false,
      'id': 'reactflow__edge-Solar_Panel_7328output-1-Solar_Panel_7328-Electricity_LB_2698input-2-Electricity_LB_2698'
    }
  ],
  'natural_gas': [
    {
      'source': 'Natural_Gas_3631',
      'sourceHandle': 'output-1-Natural_Gas_3631',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-4-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'selected': false,
      'hidden': false,
      'id': 'reactflow__edge-Natural_Gas_3631output-1-Natural_Gas_3631-Electricity_LB_2698input-4-Electricity_LB_2698'
    }
  ],
  'diesel': [
    {
      'source': 'Diesel_3486',
      'sourceHandle': 'output-1-Diesel_3486',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-1-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'default',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Diesel_3486output-1-Diesel_3486-Electricity_LB_2698input-1-Electricity_LB_2698',
      'selected': false,
      'hidden': false
    }
  ],
  'Grid': [
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-3-Electricity_LB_2698',
      'target': 'Grid_8207',
      'targetHandle': 'input-1-Grid_8207',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electricity_LB_2698output-3-Electricity_LB_2698-Grid_8207input-1-Grid_8207',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'Grid_8207',
      'sourceHandle': 'output-1-Grid_8207',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-5-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'hidden': false,
      'id': 'reactflow__edge-Grid_8207output-1-Grid_8207-Electricity_LB_2698input-5-Electricity_LB_2698'
    }
  ],
  'battery': [
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-1-Electricity_LB_2698',
      'target': 'Battery_5278',
      'targetHandle': 'input-1-Battery_5278',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electricity_LB_2698output-1-Electricity_LB_2698-Battery_5278input-1-Battery_5278',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'Battery_5278',
      'sourceHandle': 'output-1-Battery_5278',
      'target': 'Electricity_LB_2698',
      'targetHandle': 'input-1-Electricity_LB_2698',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Battery_5278output-1-Battery_5278-Electricity_LB_2698input-1-Electricity_LB_2698',
      'hidden': false
    }
  ],
  'ElectricPowerDemand': [
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-2-Electricity_LB_2698',
      'target': 'Electric_Power_Demand_6385',
      'targetHandle': 'input-1-Electric_Power_Demand_6385',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electricity_LB_2698output-2-Electricity_LB_2698-Electric_Power_Demand_6385input-1-Electric_Power_Demand_6385',
      'hidden': false
    }
  ],
  'electrolyzer': [
    {
      'source': 'Electrolyzer_4512',
      'sourceHandle': 'output-2-Electrolyzer_4512',
      'target': 'LB_1176',
      'targetHandle': 'input-2-LB_1176',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electrolyzer_4512output-2-Electrolyzer_4512-LB_1176input-2-LB_1176',
      'hidden': false
    },
    {
      'source': 'Electricity_LB_2698',
      'sourceHandle': 'output-2-Electricity_LB_2698',
      'target': 'Electrolyzer_4512',
      'targetHandle': 'input-2-Electrolyzer_4512',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Electricity_LB_2698output-2-Electricity_LB_2698-Electrolyzer_4512input-2-Electrolyzer_4512',
      'hidden': false
    }
  ],
  'hydrogen_storage': [
    {
      'source': 'LB_1176',
      'sourceHandle': 'output-1-LB_1176',
      'target': 'Hydrogen_Storage_2459',
      'targetHandle': 'input-1-Hydrogen_Storage_2459',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-LB_1176output-1-LB_1176-Hydrogen_Storage_2459input-1-Hydrogen_Storage_2459',
      'hidden': false,
      'selected': false
    },
    {
      'source': 'Hydrogen_Storage_2459',
      'sourceHandle': 'output-1-Hydrogen_Storage_2459',
      'target': 'LB_1176',
      'targetHandle': 'input-1-LB_1176',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-Hydrogen_Storage_2459output-1-Hydrogen_Storage_2459-LB_1176input-1-LB_1176',
      'selected': false,
      'hidden': false
    }
  ],
  'external_hydrogen': [
    {
      'source': 'External_Gray_Supplier_7804',
      'sourceHandle': 'output-1-External_Gray_Supplier_7804',
      'target': 'LB_1176',
      'targetHandle': 'input-3-LB_1176',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-External_Gray_Supplier_7804output-1-External_Gray_Supplier_7804-LB_1176input-3-LB_1176',
      'hidden': false,
      'selected': false
    }
  ],
  'HydrogenDemand': [
    {
      'source': 'LB_1176',
      'sourceHandle': 'output-2-LB_1176',
      'target': 'Hydrogen_Demand_2319',
      'targetHandle': 'input-1-Hydrogen_Demand_2319',
      'data': {
        'parentNode': ''
      },
      'type': 'smoothstep',
      'style': {
        'stroke': '#7A3BC9'
      },
      'markerEnd': {
        'type': 'arrowclosed' as MarkerType,
        'color': '#7A3BC9'
      },
      'id': 'reactflow__edge-LB_1176output-2-LB_1176-Hydrogen_Demand_2319input-1-Hydrogen_Demand_2319',
      'hidden': false
    }
  ]
};

export const WIZARD_SCENARIO_VIEWPORT = {
  'x': 60.173310573293634,
  'y': 71.84470229164847,
  'zoom': 0.6
};
