import * as Yup from 'yup';
import { NUMBER } from '../constants/numbers';
import { MESSAGES } from '../constants/validationMessages';
import { hasAtLeastOneKey } from './helper';

// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).+$/;
const onlyNumberRegex = /\d{1,6}/;
const discountRate = /^(0(\.\d+)?|1(\.0+)?)$/;
const onlyNumbDecRegex = /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/;
const decNegativeRegex = /^[+-]?\d{1,118}(\.\d{1,2})?$/;
const onlyNumberRegexWithoutDecimal = /^[0-9]{1,6}$/;
const numbFromOneToHundred = /^(?:[1-9]|[1-4][0-9]|100)$/;
export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const nameRegex = /[a-zA-Z]/;

export const SIGN_UP_SCHEMA = Yup.object({
  company_name: Yup.string()
    .trim()
    .required(MESSAGES.COMPANY_NAME_REQ),
  company_description: Yup.string()
    .trim()
    .required(MESSAGES.COMPANY_DESC_REQ),
  email: Yup.string()
    .trim()
    .email(MESSAGES.EMAIL_INVAILD)
    .required(MESSAGES.EMAIL_REQUIRED)
    .matches(emailRegex, MESSAGES.EMAIL_INVAILD),
  password: Yup.string()
    .min(NUMBER.N8, MESSAGES.PASSWORD_INVALID)
    .matches(
      passwordRegex,
      MESSAGES.PASSWORD_INVALID
    )
    .required(MESSAGES.PASS_REQUIRED)
});

export const SIGN_IN_SCHEMA = Yup.object({
  email: Yup.string()
    .required(MESSAGES.EMAIL_REQUIRED)
    .trim()
    .email(MESSAGES.EMAIL_INVAILD)
    .matches(emailRegex, MESSAGES.EMAIL_INVAILD),
  password: Yup.string()
    .required(MESSAGES.PASS_REQUIRED)
});

export const EDIT_PROFILE_SCHEMA = Yup.object({
  firstName: Yup.string()
    .trim()
    .required(MESSAGES.REQUIRED_FIRST_NAME),
  lastName: Yup.string()
    .trim()
    .required(MESSAGES.REQUIRED_LAST_NAME),
  email: Yup.string()
    .trim()
    .email(MESSAGES.EMAIL_INVAILD)
    .required(MESSAGES.EMAIL_REQUIRED)
    .matches(emailRegex, MESSAGES.EMAIL_INVAILD)
});

export const CREATE_PROJECT_SCHEMA = Yup.object({
  name: Yup.string()
    .trim()
    .required(MESSAGES.PROJECT_NAME_REQ),
  description: Yup.string()
    .trim()
    .required(MESSAGES.PROJECT_DESC_REQ),
  project_type: Yup.string()
    .required(MESSAGES.PROJECT_TYPE_REQ)
});

export const EMAIL_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .required(MESSAGES.EMAIL_REQUIRED)
    .trim()
    .required(MESSAGES.EMAIL_REQUIRED)
    .matches(emailRegex, MESSAGES.EMAIL_INVAILD)
});

export const RESET_PASS_VALIDATION_SCHEMA = Yup.object({
  code: Yup.string()
    .required(MESSAGES.OTO_REQ)
    .matches(onlyNumberRegex, MESSAGES.INVALID_CODE)
    .min(NUMBER.N6, MESSAGES.INVALID_CODE)
    .max(NUMBER.N6, MESSAGES.INVALID_CODE),
  password: Yup.string()
    .required(MESSAGES.NEW_PASSWORD_REQ)
    .min(NUMBER.N8, MESSAGES.PASSWORD_INVALID)
    .matches(
      passwordRegex,
      MESSAGES.PASSWORD_INVALID
    )
});

export const CHANGE_PASS_VALIDATION_SCHEMA = Yup.object({
  oldPassword: Yup.string()
    .min(NUMBER.N8, MESSAGES.PASSWORD_INVALID)
    .matches(
      passwordRegex,
      MESSAGES.PASSWORD_INVALID
    )
    .required(MESSAGES.CURRENT_PASS_REQ),
  newPassword: Yup.string()
    .min(NUMBER.N8, MESSAGES.PASSWORD_INVALID)
    .matches(
      passwordRegex,
      MESSAGES.PASSWORD_INVALID
    )
    .required(MESSAGES.NEW_PASSWORD_REQ)
});

export const SET_PASS_VALIDATION_SCHEMA = Yup.object({
  newPassword: Yup.string()
    .min(NUMBER.N8, MESSAGES.PASSWORD_INVALID)
    .matches(
      passwordRegex,
      MESSAGES.PASSWORD_INVALID
    )
    .required(MESSAGES.NEW_PASSWORD_REQ)
});

export const CREATE_SCENARIO_SCHEMA = Yup.object({
  name: Yup.string()
    .trim()
    .required(MESSAGES.SCENARIO_NAME_REQ),
  description: Yup.string()
    .trim()
    .required(MESSAGES.SCENARIO_DESC_REQ),
  project_type: Yup.string()
});

export const CARBON_SETTINGS_SCHEMA = Yup.object({
  subsidies: Yup.string()
    .required(MESSAGES.SUBSIDIES_SETTINGS)
    .matches(decNegativeRegex, MESSAGES.DECIMAL_VALIDATION)
  // sequestration_type: Yup.string()
  //   .required(MESSAGES.SEQUESTRATION_REQUIRED),
  // pc_scheme: Yup.string()
  //   .required(MESSAGES.PC_SCHEME_REQUIRED)
});

export const FINANCIAL_SETTINGS_SCHEMA = Yup.object({
  discountRate: Yup.string()
    .required(MESSAGES.DISCOUNT_RATE_REQURED)
    .matches(onlyNumbDecRegex, MESSAGES.PERCENT_VALIDATION),
  ccTax: Yup.string()
    .required(MESSAGES.CC_TAX_REQUIRED)
    .matches(decNegativeRegex, MESSAGES.DECIMAL_VALIDATION),
  terminalValue: Yup.string()
    .required(MESSAGES.TERMINAL_VALUE_REQUIRED)
    .matches(onlyNumbDecRegex, MESSAGES.PERCENT_VALIDATION),
  noOfYears: Yup.string()
    .required(MESSAGES.YEARS_REQUIRED)
    .matches(numbFromOneToHundred, MESSAGES.RANGE_NUMBERS),
  startYear: Yup.string()
    .required(MESSAGES.START_YEAR)
    .matches(onlyNumberRegexWithoutDecimal, MESSAGES.INCORRECT_NUMBER_FORMAT),
  economic_model: Yup.number()
    .min(NUMBER.N1, `Value must be at least ${NUMBER.N1}`)
    .max(NUMBER.N4, `Value must be less than or equals to ${NUMBER.N4}`)
    .required(MESSAGES.REQUIRED),
  fixed_charge_rate: Yup.number()
    .min(NUMBER.N0, `Value must be at least ${NUMBER.N0}`)
    .max(NUMBER.N1, `Value must be less than or equals to ${NUMBER.N1}`)
    .required(MESSAGES.REQUIRED),
  inflation_rate_during_construction: Yup.number()
    .min(NUMBER.N0, `Value must be at least ${NUMBER.N0}`)
    .max(NUMBER.N1, `Value must be less than or equals to ${NUMBER.N1}`)
    .required(MESSAGES.REQUIRED),
  LicenseFees: Yup.number()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.INVALID_NUMBER),
  PermittingCosts: Yup.number()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.INVALID_NUMBER),
  TaxCredits: Yup.number()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.INVALID_NUMBER),
  TaxCredit45Q: Yup.number()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.INVALID_NUMBER),
  DebtInterestPayment: Yup.number()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.INVALID_NUMBER),
  DirectorExpenses: Yup.number()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.INVALID_NUMBER),
  TotalCOGS: Yup.number()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.INVALID_NUMBER),
  DirectExpenses: Yup.number()
    .required(MESSAGES.REQUIRED)
    .typeError(MESSAGES.INVALID_NUMBER)
});

export const POWER_SIM_VALIDATION_SCHEMA = Yup.object({
  battery: Yup.string()
    .required(MESSAGES.REQUIRED),
  solar_panel: Yup.string()
    .required(MESSAGES.REQUIRED),
  turbine: Yup.string()
    .required(MESSAGES.REQUIRED),
  grid_demand: Yup.string()
    .required(MESSAGES.REQUIRED),
  grid_supply: Yup.string()
    .required(MESSAGES.REQUIRED),
  pdmr: Yup.string()
    .required(MESSAGES.REQUIRED)
});

export const EV_SETTINGS_SCHEMA = Yup.object({
  noOfYears: Yup.string()
    .required(MESSAGES.YEARS_REQUIRED)
    .matches(onlyNumberRegexWithoutDecimal, MESSAGES.INCORRECT_NUMBER_FORMAT),
  startYear: Yup.string()
    .required(MESSAGES.START_YEAR)
    .matches(onlyNumberRegexWithoutDecimal, MESSAGES.INCORRECT_NUMBER_FORMAT)
});

export const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().trim()
    .required(MESSAGES.REQUIRED_FIRST_NAME)
    .matches(nameRegex, MESSAGES.ALPHABET_REQUIRED),
  lastName: Yup.string().trim()
    .required(MESSAGES.REQUIRED_LAST_NAME)
    .matches(nameRegex, MESSAGES.ALPHABET_REQUIRED),
  userName: Yup.string().trim()
    .required(MESSAGES.REQUIRED_EMAIL)
    .matches(emailRegex, MESSAGES.INVALID_EMAIL),
  role: Yup.string()
    .required(MESSAGES.REQUIRED_ROLE),
  companyID: Yup.string().required(MESSAGES.REQUIRED_COMPANY)
});

export const FORM_NAME_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().trim()
    .required(MESSAGES.REQUIRED_FIRST_NAME)
    .matches(nameRegex, MESSAGES.ALPHABET_REQUIRED),
  lastName: Yup.string().trim()
    .required(MESSAGES.REQUIRED_LAST_NAME)
    .matches(nameRegex, MESSAGES.ALPHABET_REQUIRED)
});

export const ADD_NEW_COMPANY_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().trim()
    .required(MESSAGES.COMPANY_NAME_REQUIRED)
    .matches(nameRegex, MESSAGES.ALPHABET_REQUIRED),
  email: Yup.string().trim()
    .required(MESSAGES.REQUIRED_EMAIL)
    .matches(emailRegex, MESSAGES.INVALID_EMAIL),
  accessToProjectType: Yup.string().required(MESSAGES.ACCESS_PROJECT_TYPE_REQUIRED),
  description: Yup.string().trim().required(MESSAGES.DESCRIPTION_REQUIRED),
  accessToObjects: Yup.array().required(MESSAGES.ACCESS_TO_OBJECTS_REQUIRED)
});

export const EDIT_NEW_COMPANY_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().trim()
    .required(MESSAGES.COMPANY_NAME_REQUIRED)
    .matches(nameRegex, MESSAGES.ALPHABET_REQUIRED),
  email: Yup.string().trim()
    .required(MESSAGES.REQUIRED_EMAIL)
    .matches(emailRegex, MESSAGES.INVALID_EMAIL),
  accessToProjectType: Yup.array().min(NUMBER.N1, MESSAGES.ACCESS_PROJECT_TYPE_REQUIRED).required(MESSAGES.ACCESS_PROJECT_TYPE_REQUIRED),
  description: Yup.string().trim().required(MESSAGES.DESCRIPTION_REQUIRED)
});

export const ADD_NEW_PARAMETER_SCHEMA = Yup.object().shape({
  propertyLabel: Yup.string()
    .required('Property name is required')
    .max(NUMBER.N25, 'Property name must be 25 characters or less'),
  propertyType: Yup.string()
    .required('Property type is required'),
  unit: Yup.string().required('Unit is required'),
  propertyValue: Yup.string().required('Value is required'),
  description: Yup.string()
    .required('Property description is required')
    .max(NUMBER.N225, 'Property description must be 225 characters or less')
});

export const ADD_NEW_PARAMETER_SCHEMA_LOCATION = Yup.object().shape({
  propertyLabel: Yup.string()
    .required('Property name is required')
    .max(NUMBER.N25, 'Property name must be 25 characters or less'),
  propertyType: Yup.string()
    .required('Property type is required'),
  unit: Yup.string().required('Unit is required'),
  description: Yup.string()
    .required('Property description is required')
    .max(NUMBER.N225, 'Property description must be 225 characters or less')
});

export const MONTE_CARLO_FORM_SCHEMA = Yup.object().shape({
  type_of_analysis: Yup.object().shape({
    value: Yup.string().required('Value is required'),
    label: Yup.string().required('Label is required')
  }),
  settingForm: Yup.object().required('Setting form is required').test('hasAtLeastOneKey', 'Setting form must contain at least one key', hasAtLeastOneKey),
  output_values_to_track: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required('Value is required'),
      label: Yup.string().required('Label is required')
    })
  ).required('Output values to track are required')
    .min(1, 'At least one output value is required'),
  ITERATIONS: Yup.number().required('Iterations are required').min(1, 'Iterations must be at least 1')
});
