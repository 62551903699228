import { createSlice } from '@reduxjs/toolkit';
import { IScenarioDetails } from '../constants';
;

interface IWorkbenchProps {
  theme: string
  scenarioDetails: IScenarioDetails
  lat: number
  lng: number
  startYear: number
  endYear: number
  radius: number
  isDuplicate: boolean
  unsavedChanges: boolean
  showComment: boolean
  simulationData: any
}

const initialState: IWorkbenchProps = {
  theme: '',
  scenarioDetails: {
    _id: '',
    projectID: '',
    name: '',
    description: '',
    userCreator: '',
    subProject_Type: '',
    createDate: '',
    id: ''
  },
  lat: 0,
  lng: 0,
  startYear: 0,
  endYear: 0,
  radius: 0,
  isDuplicate: false,
  unsavedChanges: false,
  showComment: false,
  simulationData: {}
};

const workbenchSliceEV = createSlice({
  name: 'workbenchEV',
  initialState,
  reducers: {
    resetWorkbenchEv: () => initialState,
    updateWorkbenchDataEV: (state, payload) => {
      return {
        ...state,
        ...payload.payload
      };
    },
    updateTheme: (state, payload) => {
      return { ...state, theme: payload.payload };
    },
    updateWorkbenchScenario: (state, payload) => {
      return { ...state, scenarioDetails: payload.payload };
    },

    updateUnsavedChange: (state, payload) => {
      return { ...state, unsavedChanges: payload.payload };
    },
    showComments: (state, payload) => {
      return { ...state, showComment: payload.payload };
    }
  }
});

export const {
  resetWorkbenchEv,
  updateWorkbenchDataEV,
  updateWorkbenchScenario,
  updateTheme,
  updateUnsavedChange,
  showComments
} = workbenchSliceEV.actions;

export default workbenchSliceEV.reducer;
