import { Formik, FormikProps } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { formatNumberWithCommas, getWorkbenchPayload, hasPermissionToEditParamerters, locationLogo, parseFormattedNumber, threeDotHorizontal } from '../../utils';
import PopoverButton from '../layout/PopoverButton';
import { ERROR_FIELD_CLASS, FORMULA_TITLE, IProerties, LABELS, MESSAGES, NUMBER } from '../../constants';
import TurbineHeightModal from './TurbineHeightModal';
import SchedulingModal from './SchedulingModal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import GoogleMaps from '../shared/GoogleMaps';
import { updateNodes } from '../../redux/workbenchSlice';
import { useSaveWorkbenchDataMutation } from '../../redux/services/workbenchApis';
import { toast } from 'react-toastify';
import { scenarioApis } from '../../redux/services/scenarioApis';
import { useReactFlow } from 'reactflow';
import { useLazyGetComponentPropertiesQuery } from '../../redux/services/admin/objectManagementApis';
import { useComponentProperties } from '../../hooks/useComponentProperty';

interface IProps {
  show: boolean
  handleClose: () => void
  paramName: string
}

const UpdateParameterValue: FC<IProps> = ({ show, handleClose, paramName }) => {
  const { nodes } = useAppSelector(state => state.workbench);
  const workbenchNodes = useAppSelector(state => state.workbench.nodes);
  const dispatch = useAppDispatch();
  const workbenchData = useAppSelector(state => state.workbench);
  const userPermission = workbenchData.scenarioDetails.permission;
  const { permissions } = useAppSelector(state => state.auth);
  const { BaselineStatus } = useAppSelector(state => state.scenarioDetails);
  const [getComponentProperties] = useLazyGetComponentPropertiesQuery();
  const { updateNodeProperties } = useComponentProperties();
  const [showGoogleMaps, setShowGoogleMaps] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<IProerties | object>({});
  const [locationLat, setLocationLat] = useState<string>('');
  const [locationLng, setLocationLng] = useState<string>('');
  const formikRef = useRef<FormikProps<any> | null>(null);
  const [initialOPValues, setInitialOPvalues] = useState<any>({});
  const [nodesData, setNodesData] = useState<any>();
  const [ShowTurbineHeightModal, setShowTurbineHeightModal] = useState(false);
  const [newNodeData, setNewNodeData] = useState<any>();
  const [saveWorkbenchData, { isLoading: saveWorkbenchLoading }] = useSaveWorkbenchDataMutation();
  const [ShowSchedulingModal, setShowSchedulingModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [componentDataState, setComponentDataState] = useState<any>();
  const [finalUpdatedData, setFinalUpdatedData] = useState(JSON.parse(JSON.stringify(nodes)));
  const [componentProperties, setComponentProperties] = useState<any>();
  const { setNodes } = useReactFlow();

  const canEditParameters = hasPermissionToEditParamerters(userPermission ?? '', permissions, !!BaselineStatus);

  const openGoogleMap = (property: IProerties) => {
    setSelectedLocation(property);
    const locationLatLng: any = paramName.split(',');
    setLocationLat(locationLatLng ? locationLatLng[0] : '30.24');
    setLocationLng(locationLatLng ? locationLatLng[1] : '-97.74');
    setShowGoogleMaps(true);
  };

  useEffect(() => {
    const finalNodesData = nodes.filter((ele: any) => Object.hasOwn(ele.data?.formulaTitle, paramName));
    setNodesData(finalNodesData);
    setFinalUpdatedData(JSON.parse(JSON.stringify(nodes)));
  }, [nodes]);

  const handleFormSubmitt = async () => {
    dispatch(updateNodes(finalUpdatedData));
    setNodes(finalUpdatedData);
    const payload = getWorkbenchPayload(workbenchData);
    saveWorkbenchData(payload).then((res) => {
      if ('data' in res) {
        toast.success(MESSAGES.PROPERTIES_SAVED);
        handleClose();
        scenarioApis.util.invalidateTags(['GetScenarioDetails']);
      } else {
        toast.error(MESSAGES.SOMETHING_WENT_WRONG);
      }
    });
  };

  const iterateOnDataToChangeProperty = (data: any, id: string, value: any) => {
    for (const item of data) {
      if (item.id === id) {
        const propertyName = item.data?.formulaTitle[paramName];
        if (Object.prototype.hasOwnProperty.call(item.data?.propertiesValues, propertyName)) {
          item.data.propertiesValues[propertyName] = value;
          item.data[propertyName] = value;
        }
      }
    }
    return data;
  };

  const handlePropertyChange = (value: any, id: string) => {
    let copiedNodeData = JSON.parse(JSON.stringify(finalUpdatedData));
    let nodeDataToMap = JSON.parse(JSON.stringify(nodesData));
    copiedNodeData = iterateOnDataToChangeProperty(copiedNodeData, id, value);
    nodeDataToMap = iterateOnDataToChangeProperty(nodeDataToMap, id, value);
    setFinalUpdatedData(copiedNodeData);
    setNodesData(nodeDataToMap);
  };

  const updateTurbineHeight = (prop: IProerties, value: string) => {
    const nodeProperties = workbenchNodes.find((ele: any) => ele.data.class === componentDataState?.class);
    nodeProperties !== undefined && handlePropertyChange(value, nodeProperties.id);
    // const properties = { ...nodeProperties?.data?.properties };
    // properties[prop.formulaTitle] = value;
    // setInitialOPvalues(properties);
    setShowTurbineHeightModal(false);
  };

  const updateSchedule = (prop: IProerties, value: any, scheduleType: string) => {
    const nodeProperties = workbenchNodes.find((ele: any) => ele.data.class === componentDataState?.class);
    const properties = { ...nodeProperties?.data?.properties };
    properties[prop.formulaTitle] = { scheduleType, scheduleData: value };
    nodeProperties !== undefined && updateNodeProperties(nodeProperties.id, properties, componentProperties);
    setShowSchedulingModal(false);
  };

  const handleSelectedProperty = (node: any) => {
    setComponentDataState(node);
    getComponentProperties(node.class).then((res: any) => {
      const filterData = res?.data.filter((ele: any) => ele.paramType === 'input');
      setComponentProperties(filterData);
      const property = res?.data?.find((ele: any) => paramName === ele.propertyName);
      setSelectedProperty(property);
    });
    setShowSchedulingModal(true);
  };

  const showErrorToast = () => {
    toast.error(`The property can't be edited for this component since the property name has been changed`);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="observations-modal modal-dialog-centered modal-588"
        className="change-pw-modal create-modal update-parameters-modal setting-modal update-parameter-modal"
      >
        <Modal.Header>
          <button className='close-btn' onClick={handleClose}>
            <span className="icon-close-grey-icon"></span>
          </button>
        </Modal.Header>
        <Modal.Title className="modal-head modal-head-medium component-name">
          {paramName}
        </Modal.Title>

        <Modal.Body>
          {
            <Formik
              innerRef={formikRef}
              initialValues={initialOPValues}
              //   validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={() => {
                handleFormSubmitt();
              }}
            >
              {({ values, errors, handleSubmit, handleChange, setFieldValue, submitCount }) => {
                return (
                  <Form className="" onSubmit={handleSubmit}>
                    <>
                      <div className={`object-form-wrap ${canEditParameters ? '' : 'read-only'} `}>
                        <div className="row">
                          {/* eslint-disable-next-line complexity */}
                          {nodesData !== undefined && nodesData.length > 0 && nodesData.map((ele: any, index: number) => {
                            const propertyName = ele.data.formulaTitle[paramName];
                            return (
                              <div
                                className={`${paramName === LABELS.LOCATION || paramName === LABELS.ABATEMENT_LOCATION ? 'col-md-12' : 'col-md-6'}`}
                                key={`${paramName}--${index}`}>
                                <Form.Group
                                  className={'form-group'}
                                  controlId={ele.data.componentDisplayName}
                                >
                                  <div className={typeof ele.data.propertiesValues[propertyName] === 'object' ? 'demand-with-arrows' : 'parameters-dropdown-wrap'}
                                  >
                                    <div className="tooltip-container">
                                      <Form.Label className='parameter-label'>{ele.data.componentDisplayName}</Form.Label>
                                      {/* {ele.data.description && <div className="tooltip-text">{ele.data.description[propertyName]}</div>} */}
                                    </div>
                                    {typeof ele.data.propertiesValues[propertyName] === 'object' &&
                                                    <Button className='btn-no-outline btn btn-primary' onClick={() => handleSelectedProperty(ele.data)}>
                                                      <img src={threeDotHorizontal} alt="three dot icon" />
                                                    </Button>
                                    }
                                    {/* {
                                    (Array.isArray(componentData[ele.formulaTitle]) && !ele.scheduleOn) &&
                                                    <PopoverButton data={componentData[ele.formulaTitle] as any[]} />
                                  } */}

                                  </div>
                                  <Form.Control
                                    type={'text'}
                                    title=""
                                    name={ele.componentDisplayName}
                                    value={ele.propertyType === 'location' ? values[paramName]
                                      : typeof ele.data.propertiesValues[propertyName] === 'object' && !Array.isArray(ele.data.propertiesValues[propertyName])
                                        ? formatNumberWithCommas(ele.data.propertiesValues[propertyName]?.scheduleType || '')
                                        : formatNumberWithCommas(ele.data[propertyName])}
                                    onChange={event => {
                                      event.target.value = parseFormattedNumber(event.target.value);
                                      handlePropertyChange(event.target.value, ele.id);
                                    }}
                                    className={`big-input ${errors[ele.data.formulaTitle] ? 'error-field' : ''}`}
                                    //   onKeyDown={keyPressHandle}
                                    disabled={ele.hasDatasource || typeof ele.data.propertiesValues[propertyName] === 'object'}
                                    readOnly={paramName === LABELS.LOCATION || paramName === LABELS.ABATEMENT_LOCATION ||
                                    paramName === FORMULA_TITLE.TURBINE_HEIGHT}
                                  />

                                  {/* {(values[ele.formulaTitle] === '' && !errors[ele.formulaTitle] && submitCount > NUMBER.N0) &&
                                                  <span className="error-msg">{`${ele.propertyName} field is required`}</span>
                                }
                                {errors[ele.formulaTitle] && <span className="error-msg">{`${errors[ele.formulaTitle] as string}`}</span>} */}
                                  {
                                    (paramName === LABELS.LOCATION || paramName === LABELS.ABATEMENT_LOCATION) &&
                                                  <span className="input-logo" onClick={() => openGoogleMap(ele)}>
                                                    <img src={locationLogo} alt="logo img" />
                                                  </span>
                                  }
                                  {(paramName !== FORMULA_TITLE.TURBINE_HEIGHT && paramName !== FORMULA_TITLE.SCHEDULE_DEMAND &&
                                                  paramName !== LABELS.LOCATION && paramName !== LABELS.ABATEMENT_LOCATION && ele.data.units) &&
                                                  <div className="custom-select-main capital-cost-select" title={ele.data.units[propertyName]}>
                                                    <div>
                                                    </div>
                                                    <Select
                                                      className="custom-select-wrp"
                                                      classNamePrefix="select"
                                                      defaultValue={[{
                                                        value: ele.data.units[propertyName],
                                                        label: ele.data.units[propertyName],
                                                        isDisabled: true
                                                      }]}
                                                      isDisabled={true}
                                                      name="color"
                                                      options={[{
                                                        value: ele.data.units[propertyName],
                                                        label: ele.data.units[propertyName],
                                                        isDisabled: true
                                                      }]}
                                                    // menuIsOpen={true}
                                                    />
                                                  </div>
                                  }
                                  {propertyName === FORMULA_TITLE.TURBINE_HEIGHT &&
                                                  <Button className='btn btn-no-outline turbine-arrow-btn' onClick={() => setShowTurbineHeightModal(true)}></Button>
                                  }
                                </Form.Group>
                                {
                                  ShowTurbineHeightModal && propertyName === FORMULA_TITLE.TURBINE_HEIGHT &&
                                                <TurbineHeightModal
                                                  show={ShowTurbineHeightModal}
                                                  setShowHide={(action: boolean) => setShowTurbineHeightModal(action)}
                                                  list={ele.data.listOfTurbines as string[]}
                                                  defaultValue={ele.data.turbineHeight}
                                                  property={ele.data.takeOfPercent}
                                                  onSave={updateTurbineHeight}
                                                />
                                }

                              </div>
                            );
                          })}

                          {ShowSchedulingModal && selectedProperty === undefined &&
                            showErrorToast()
                          }

                          {ShowSchedulingModal && selectedProperty !== null && selectedProperty !== undefined &&
                                            <SchedulingModal
                                              show={ShowSchedulingModal}
                                              setShowHide={(action: boolean) => setShowSchedulingModal(action)}
                                              onSave={updateSchedule}
                                              componentData={componentDataState}
                                              selectedProperty={selectedProperty}
                                            />
                          }
                        </div>
                      </div>

                      <Modal.Footer className='cursor-move'>
                        <Button className="primary" type="submit" disabled={!canEditParameters}>
                          {LABELS.SAVE_PROPERTIES}
                        </Button>
                      </Modal.Footer>
                    </>
                  </Form>
                );
              }
              }
            </Formik>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateParameterValue;
